const routing = require('./routing');

module.exports = Object.freeze({
  [routing.ABANDONED_CART]: '/cart/PageAbandonedCart/[id]',
  [routing.ABOUT_US]: '/content/PageAboutUs',
  [routing.ACCOUNT_TRANSACTIONS]: '/profile/PageAccountTransactions',
  [routing.ALERT_SETTINGS]: '/dashboard/PageAlertSettings',
  [routing.AUTO_SAVINGS]: '/content/PageAutoSavings',
  [routing.BAD_REQUEST]: '/400',
  [routing.BILLING_ADDRESS]: '/profile/PageBillingAddress',
  [routing.BLOG_ARTICLE]: '/blog/[category]/[slug]',
  [routing.BLOG_CATEGORY]: '/blog/categories/[slug]',
  [routing.BLOG_HOME]: '/blog/PageBlogHome',
  [routing.BLOG_SEARCH]: '/blog/PageBlogSearch',
  [routing.BLOG_TAG]: '/blog/tags/[slug]',
  [routing.BUY]: '/product/shop/[metal]',
  [routing.CART]: '/cart/PageCart',
  [routing.CHANGE_PASSWORD]: '/auth/PageChangePassword',
  [routing.CHARTS]: '/content/PageCharts/[metalSlug]/[slug*]',
  [routing.CHECKOUT_BILLING]: '/checkout/PageCheckout',
  [routing.CHECKOUT_PAYMENT]: '/checkout/PageCheckout',
  [routing.CHECKOUT_REVIEW]: '/checkout/PageCheckout',
  [routing.CHECKOUT_SHIPPING]: '/checkout/PageCheckout',
  [routing.CHECKOUT_THANKS]: '/checkout/[orderId]',
  [routing.CONTACT]: '/contact/PageContact',
  [routing.CONTACT_FORM]: '/contact/PageContactForm',
  [routing.DELIVERY_CLAIMS_BILLING_ADDRESS]: '/dashboard/PageClaimsBillingAddress',
  [routing.DELIVERY_CLAIMS_BREAKDOWN]: '/dashboard/PageClaimsBreakdown',
  [routing.DELIVERY_CLAIMS_DELIVERY]: '/dashboard/PageClaimsDelivery',
  [routing.DELIVERY_CLAIMS_ORDER_REVIEW]: '/dashboard/PageClaimsOrderReview',
  [routing.DELIVERY_CLAIMS_PAYMENT_METHOD]: '/dashboard/PageClaimsPaymentMethod',
  [routing.DELIVERY_CLAIMS_THANK_YOU]: '/dashboard/thankYou/[claimId]',
  [routing.DEPOSIT]: '/profile/PageDeposit',
  [routing.DETAILS_GIFT]: '/gifts/details/[reference]',
  [routing.DONE_SIGN_UP]: '/auth/PageSignUp',
  [routing.EMAIL_UNSUBSCRIBE]: '/profile/email-unsubscribe/[token]',
  [routing.EMAIL_UNSUBSCRIBE]: '/profile/email-unsubscribe/[token]/edit',
  [routing.EMERGENCY_PEOPLE]: '/profile/PageEmergencyPeople',
  [routing.EXAMPLE]: '/example/PageComponent',
  [routing.FAQ]: '/faq/PageFaq',
  [routing.FAQ_SEARCH]: '/faq/PageFaqSearch',
  [routing.FAQ_SHOW]: '/faq/PageFaqShow/[slug]',
  [routing.FORGOTTEN_PASSWORD]: '/auth/PageForgottenPassword',
  [routing.GIFTS]: '/dashboard/PageGifts',
  [routing.GIFT_CONFIRMATION]: '/dashboard/PageGiftConfirmation',
  [routing.GIFT_PENDING]: '/gifts/[reference]',
  [routing.GIFT_RECIPIENT]: '/dashboard/PageRecipient',
  [routing.GIFT_SUBMIT]: '/dashboard/PageGiftSubmit',
  [routing.HOME]: '/',
  [routing.HOME_BUY]: '/product/shop/PageHomeShop',
  [routing.IMPRESSUM]: '/content/PageImpressum',
  [routing.INTERNAL_ERROR]: '/500',
  [routing.KYC_ANNUAL_INCOME]: '/auth/PageKYC',
  [routing.KYC_BUSINESS_SECTOR]: '/auth/PageKYC',
  [routing.KYC_COUNTRIES_OF_RESIDENCE]: '/auth/PageKYC',
  [routing.KYC_DATE_OF_BIRTH]: '/auth/PageKYC',
  [routing.KYC_DONE]: '/auth/PageKYC',
  [routing.KYC_EXPOSED_PERSON]: '/auth/PageKYC',
  [routing.KYC_FORECASTED_ANNUAL_INVESTMENTS]: '/auth/PageKYC',
  [routing.KYC_LOCATION]: '/auth/PageKYC',
  [routing.KYC_NATIONALITIES]: '/auth/PageKYC',
  [routing.KYC_OCCUPATION]: '/auth/PageKYC',
  [routing.KYC_PHONE_NUMBER]: '/auth/PageKYC',
  [routing.KYC_PHOTO_IDENTIFICATION]: '/auth/PageKYC',
  [routing.KYC_SOURCES_OF_FUNDS]: '/auth/PageKYC',
  [routing.MAINTENANCE]: '/maintenance',
  [routing.MOBILE_APP_REDIRECTION]: '/redirection/MobileAppRedirection',
  [routing.NOT_FOUND]: '/404',
  [routing.PAGES]: '/content/Pages',
  [routing.PASSWORD_SETTINGS]: '/profile/PagePasswordSettings',
  [routing.PAYMENTS]: '/profile/PagePayments',
  [routing.PRECIOUS_METAL_GUIDE]: '/precious-metal-guide/PagePreciousMetalGuide',
  [routing.PRECIOUS_METAL_GUIDE_LEVEL1]: '/precious-metal-guide/[slug]',
  [routing.PRECIOUS_METAL_GUIDE_LEVEL2]: '/precious-metal-guide/[slug]/[subSlug]',
  [routing.PRECIOUS_METAL_GUIDE_LEVEL3]: '/precious-metal-guide/[slug]/[subSlug]/[subSubSlug]',
  [routing.PRICING]: '/content/PagePricing',
  [routing.PRODUCT_SHOW]: '/product/PageProductShow/[metal]/product/[slug]',
  [routing.PROFILE]: '/profile/PageProfile',
  [routing.PURCHASING_LIMIT]: '/content/PagePurchasingLimit',
  [routing.REFERRALS]: '/content/PageReferrals',
  [routing.REFERRALS_TERMS]: '/content/PageReferralsTermsConditions',
  [routing.REFERRAL]: '/r/[slug]',
  [routing.SAFERPAY_REDIRECTION]: '/redirection/PageSaferpayRedirection',
  [routing.SAFERPAY_TRANSACTION]: '/redirection/PageSaferpayTransaction',
  [routing.SAVING_ASSISTANT]: '/saving-assistant/PageSavingAssistant',
  [routing.SEARCH]: '/product/PageSearch',
  [routing.SHIPPING_ADDRESS]: '/profile/PageShippingAddress',
  [routing.SHOP_SUBCATEGORY]: '/product/PageShopSubcategory/[metal]/[slug]',
  [routing.SIGN_IN]: '/auth/PageSignIn',
  [routing.SIGN_UP]: '/auth/PageSignUp',
  [routing.SILVER_LANDING]: '/content/PageSilverLanding',
  [routing.STORAGE_FEES]: '/dashboard/PageStorageFees',
  [routing.STORAGE_SOLUTION]: '/content/PageStorageSolution',
  [routing.TUTORIAL_VIDEOS]: '/tutorial-videos/PageTutorialVideos',
  [routing.TUTORIAL_VIDEO]: '/tutorial-videos/[slug]',
  [routing.TWO_STEPS_AUTH_SMS]: '/profile/PageTwoStepsAuthSms',
  [routing.TWO_STEPS_AUTH_TOTP]: '/profile/PageTwoStepsAuthTotp',
  [routing.UNLOCK_ACCOUNT]: '/auth/PageUnlockAccount',
  [routing.VERIFY_AUTH]: '/profile/PageVerifyAuth',
  [routing.WALLET]: '/wallet/PageWallet',
  [routing.WALLET_AUTO_SAVINGS]: '/wallet/PageWalletAutoSavings',
  [routing.WALLET_AUTO_SAVINGS_SHOW]: '/wallet/PageWalletAutoSavingsShow/[id]',
  [routing.WALLET_CLAIMS]: '/wallet/PageWalletClaims',
  [routing.WALLET_CLAIM_SHOW]: '/wallet/PageWalletClaimShow/[id]',
  [routing.WALLET_GIFTS]: '/wallet/PageWalletGifts',
  [routing.WALLET_GIFT_SHOW]: '/wallet/PageWalletGiftShow/[reference_number]',
  [routing.WALLET_ORDERS]: '/wallet/PageWalletOrders',
  [routing.WALLET_ORDER_SHOW]: '/wallet/PageWalletOrderShow/[id]',
  [routing.WALLET_SELL]: '/wallet/PageWalletSell',
  [routing.WALLET_SELL_REVIEW]: '/wallet/PageWalletSellReview',
  [routing.WALLET_SELL_THANKS]: '/wallet/PageWalletSellThanks',
  [routing.WISHLIST]: '/dashboard/PageWishList',
  [routing.WITHDRAWAL]: '/profile/PageWithdrawal',
});
