import { EColor, EColorName, ThemePalette } from '@core/type';

export const themeDefaultPalette: ThemePalette = {
  [EColor.PRIMARY]: {
    [EColor.MAIN]: EColorName.OXFORD_BLUE,
    [EColor.LIGHT]: EColorName.OXFORD_BLUE,
    [EColor.DARK]: EColorName.OXFORD_BLUE,
  },
  [EColor.SECONDARY]: {
    [EColor.MAIN]: EColorName.EPIC_BLUE,
    [EColor.LIGHT]: null,
    [EColor.DARK]: EColorName.EPIC_BLUE,
  },
  [EColor.SUCCESS]: {
    [EColor.MAIN]: EColorName.EMERALD_GREEN,
    [EColor.LIGHT]: EColorName.MINT_CREAM,
    [EColor.DARK]: null,
  },
  [EColor.WARNING]: {
    [EColor.MAIN]: EColorName.ECRU,
    [EColor.LIGHT]: EColorName.BABY_POWDER,
    [EColor.DARK]: EColorName.SWEET_HONEY,
  },
  [EColor.DANGER]: {
    [EColor.MAIN]: EColorName.BRIGHT_RED,
    [EColor.LIGHT]: EColorName.SNOW,
    [EColor.DARK]: null,
  },
  [EColor.INFO]: {
    [EColor.MAIN]: EColorName.CADET_GRAY,
    [EColor.LIGHT]: null,
    [EColor.DARK]: null,
  },
  [EColor.NEUTRAL]: {
    [EColor.MAIN]: EColorName.FRENCH_GRAY,
    [EColor.LIGHT]: EColorName.SOLITUDE,
    [EColor.DARK]: null,
  },
  [EColor.BLACK]: {
    [EColor.R100]: EColorName.PLATINUM,
    [EColor.R200]: EColorName.TIMBERWOLF,
    [EColor.R900]: EColorName.EERIE_BLACK,
  },
  [EColor.BLUE]: {
    [EColor.R50]: EColorName.SEASALT,
    [EColor.R100]: EColorName.ALICE_BLUE,
    [EColor.R200]: EColorName.OXFORD_BLUE_20,
    [EColor.R300]: EColorName.OXFORD_BLUE_30,
    [EColor.R500]: EColorName.OXFORD_BLUE,
    [EColor.R600]: EColorName.MAASTRICHT_BLUE,
    [EColor.R900]: EColorName.EERIE_BLACK,
    [EColor.A100]: EColorName.LIGHT_BLUE,
    [EColor.A200]: EColorName.COLUMBIA_BLUE,
    [EColor.A400]: EColorName.EPIC_BLUE,
    [EColor.A500]: EColorName.PERSIAN_BLUE,
    [EColor.A600]: EColorName.MOODY_BLUE,
    [EColor.A700]: EColorName.PAYNE_GRAY,
    [EColor.A800]: EColorName.ELECTRIC_BLUE,
  },
  [EColor.GRAY]: {
    [EColor.R50]: EColorName.WHITE,
    [EColor.R100]: EColorName.WHITE_SMOKE,
    [EColor.R200]: EColorName.PLATINUM,
    [EColor.R400]: EColorName.TIMBERWOLF,
    [EColor.R600]: EColorName.DAVY_GRAY,
    [EColor.R700]: EColorName.CADET_GRAY,
    [EColor.A50]: EColorName.ANTI_FLASH_WHITE,
    [EColor.A100]: EColorName.SEASALT,
    [EColor.A200]: EColorName.SOLITUDE,
    [EColor.A400]: EColorName.FRENCH_GRAY,
    [EColor.A600]: EColorName.BATTLESHIP_GRAY,
    [EColor.A800]: EColorName.LOTION,
  },
  [EColor.GREEN]: {
    [EColor.R50]: EColorName.HONEYDEW,
    [EColor.R100]: EColorName.MINT_GREEN,
    [EColor.R700]: EColorName.EMERALD_GREEN,
    [EColor.A200]: EColorName.MINT_CREAM,
    [EColor.R300]: EColorName.CHARLOTTE,
    [EColor.A500]: EColorName.BRIGHT_TURQUOISE,
    [EColor.A900]: EColorName.BLUE_STONE,
  },
  [EColor.PURPLE]: {
    [EColor.R100]: EColorName.LAVENDER,
    [EColor.R400]: EColorName.COMET,
    [EColor.R500]: EColorName.ROYAL_BLUE,
    [EColor.R600]: EColorName.PURPLE_HEART,
    [EColor.R900]: EColorName.SCARLET_GUM,
  },
  [EColor.RED]: {
    [EColor.R50]: EColorName.TEA_ROSE,
    [EColor.R300]: EColorName.LIGHT_RED,
    [EColor.R500]: EColorName.BRIGHT_RED,
    [EColor.A100]: EColorName.SNOW,
    [EColor.A200]: EColorName.MISTY_ROSE,
  },
  [EColor.WHITE]: {
    [EColor.R100]: EColorName.WHITE_10,
    [EColor.R300]: EColorName.WHITE_40,
    [EColor.R600]: EColorName.WHITE_70,
    [EColor.R700]: EColorName.WHITE_80,
    [EColor.R900]: EColorName.WHITE,
  },
  [EColor.YELLOW]: {
    [EColor.R50]: EColorName.OLD_LACE,
    [EColor.R100]: EColorName.PEARL_LUSTA,
    [EColor.R200]: EColorName.BABY_POWDER,
    [EColor.R300]: EColorName.CAPE_HONEY,
    [EColor.R400]: EColorName.WHEAT,
    [EColor.R500]: EColorName.ECRU,
    [EColor.R600]: EColorName.SWEET_HONEY,
    [EColor.R700]: EColorName.FLORAL_WHITE,
  },
  [EColor.TEXT]: {
    [EColor.SECONDARY]: {
      [EColor.MAIN]: EColorName.CADET_GRAY,
      [EColor.LIGHT]: null,
      [EColor.DARK]: EColorName.EPIC_BLUE,
    },
    [EColor.ACCENT]: {
      [EColor.MAIN]: EColorName.EPIC_BLUE,
      [EColor.LIGHT]: null,
      [EColor.DARK]: EColorName.EPIC_BLUE,
    },
    [EColor.DESTRUCTIVE]: {
      [EColor.MAIN]: EColorName.CADET_GRAY,
      [EColor.LIGHT]: null,
      [EColor.DARK]: EColorName.BRIGHT_RED,
    },
    [EColor.DANGER]: {
      [EColor.MAIN]: EColorName.BRIGHT_RED,
      [EColor.LIGHT]: EColorName.BRIGHT_RED,
      [EColor.DARK]: EColorName.BRIGHT_RED,
    },
  },
  [EColor.BACKGROUND]: {
    [EColor.SECONDARY]: {
      [EColor.MAIN]: EColorName.ANTI_FLASH_WHITE,
      [EColor.LIGHT]: EColorName.SEASALT,
      [EColor.DARK]: null,
    },
    [EColor.ACCENT]: {
      [EColor.MAIN]: EColorName.EPIC_BLUE,
      [EColor.LIGHT]: EColorName.EPIC_BLUE,
      [EColor.DARK]: null,
    },
    [EColor.WARNING]: {
      [EColor.MAIN]: EColorName.SWEET_HONEY,
      [EColor.LIGHT]: EColorName.BABY_POWDER,
      [EColor.DARK]: null,
    },
    [EColor.INFO]: {
      [EColor.MAIN]: EColorName.ALICE_BLUE,
      [EColor.LIGHT]: EColorName.WHITE,
      [EColor.DARK]: null,
    },
    [EColor.NEUTRAL]: {
      [EColor.MAIN]: EColorName.FRENCH_GRAY,
      [EColor.LIGHT]: EColorName.WHITE,
      [EColor.DARK]: null,
    },
  },
  [EColor.BOX_SHADOW]: {
    [EColor.ELEVATION_1]: `0 2px 4px -1px ${EColorName.BLACK_20}, 0 1px 10px 0 ${EColorName.BLACK_12}, 0 4px 5px 0 ${EColorName.BLACK_14}`,
    [EColor.ELEVATION_2]: `0 2px 12px ${EColorName.RIVER_BED_26}`,
    [EColor.ELEVATION_3]: `0 8px 15px ${EColorName.RIVER_BED_15}`,
    [EColor.ELEVATION_4]: `0 10px 24px ${EColorName.RIVER_BED_12}`,
    [EColor.ELEVATION_5]: `0 16px 40px ${EColorName.RIVER_BED_20}`,
    [EColor.ELEVATION_6]: `0 24px 40px ${EColorName.RIVER_BED_15}`,
  },
};
