import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconHeartSharp = ({ fill, stroke }: DynamicIconProps) => (
  <SVG xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <path
      stroke={stroke}
      fill={fill}
      stroke-width="2"
      d="M16.755 3.84a6.02 6.02 0 0 0-1.922.314 5.756 5.756 0 0 0-2.618 1.842L12 6.258l-.214-.262a5.756 5.756 0 0 0-2.619-1.842 6.02 6.02 0 0 0-1.921-.314c-1.552 0-3.01.581-4.108 1.637a5.45 5.45 0 0 0 0 7.907l8.133 7.456c.204.187.466.28.729.28.262 0 .525-.093.729-.28l8.115-7.439c2.284-2.197 2.284-5.743.018-7.924a5.888 5.888 0 0 0-4.108-1.637Z"
    />
  </SVG>
);
