const routing = require('./routing');
const {
  ELanguageTags: { FR_FR, IT_IT, EN_US, DE_DE },
} = require('./language');

const EMetalSlug = {
  GOLD_PRICE_EN: 'gold-price',
  GOLD_PRICE_FR: 'cours-or',
  GOLD_PRICE_IT: 'quotazione-oro',
  GOLD_PRICE_DE: 'goldpreis',
  SILVER_PRICE_EN: 'silver-price',
  SILVER_PRICE_FR: 'cours-argent',
  SILVER_PRICE_IT: 'quotazione-argento',
  SILVER_PRICE_DE: 'silberpreis',
  PLATINUM_PRICE_EN: 'platinum-price',
  PLATINUM_PRICE_FR: 'cours-platine',
  PLATINUM_PRICE_IT: 'quotazione-platino',
  PLATINUM_PRICE_DE: 'platinpreis',
  PALLADIUM_PRICE_EN: 'palladium-price',
  PALLADIUM_PRICE_FR: 'cours-palladium',
  PALLADIUM_PRICE_IT: 'quotazione-palladio',
  PALLADIUM_PRICE_DE: 'palladiumpreis',
};

module.exports = Object.freeze({
  [IT_IT]: Object.freeze({
    [routing.ABANDONED_CART]: '/set_cart/[id]',
    [routing.ABOUT_US]: '/chi-siamo',
    [routing.ACCOUNT_TRANSACTIONS]: '/profile/account_transactions',
    [routing.ALERT_SETTINGS]: '/dashboard/alerts',
    [routing.AUTO_SAVINGS]: '/piano-di-accumulo',
    [routing.BAD_REQUEST]: '/400',
    [routing.BILLING_ADDRESS]: '/profile/address/billing-address',
    [routing.BLOG_ARTICLE]: '/blog/[category]/[slug]',
    [routing.BLOG_CATEGORY]: '/blog/categories/[slug]',
    [routing.BLOG_HOME]: '/blog',
    [routing.BLOG_SEARCH]: '/blog/search',
    [routing.BLOG_TAG]: '/blog/tags/[slug]',
    [routing.BUY]: '/comprare/[metal]',
    [routing.CART]: '/cart',
    [routing.CHANGE_PASSWORD]: '/users/password/edit',
    [routing.CHARTS]: `/[metalSlug](${Object.values(EMetalSlug).join('|')})/[slug*]`,
    [routing.CHECKOUT_BILLING]: '/order/process_address',
    [routing.CHECKOUT_PAYMENT]: '/order/process_payment',
    [routing.CHECKOUT_REVIEW]: '/order/process_submit',
    [routing.CHECKOUT_SHIPPING]: '/order/storage_shipping',
    [routing.CHECKOUT_THANKS]: '/orders/[orderId]/thanks',
    [routing.CONTACT]: '/contatto',
    [routing.CONTACT_FORM]: '/modulo-contatto',
    [routing.DELIVERY_CLAIMS_BILLING_ADDRESS]: '/delivery_claims/billing_address',
    [routing.DELIVERY_CLAIMS_BREAKDOWN]: '/delivery_claims/breakdown',
    [routing.DELIVERY_CLAIMS_DELIVERY]: '/delivery_claims/delivery',
    [routing.DELIVERY_CLAIMS_ORDER_REVIEW]: '/delivery_claims/process_submit',
    [routing.DELIVERY_CLAIMS_PAYMENT_METHOD]: '/delivery_claims/process_payment',
    [routing.DELIVERY_CLAIMS_THANK_YOU]: '/delivery_claims/[claimId]/thanks',
    [routing.DEPOSIT]: '/profile/payments/deposit',
    [routing.DETAILS_GIFT]: '/gifts/pending/[reference]/details',
    [routing.DONE_SIGN_UP]: '/utenti/done',
    [routing.EMAIL_UNSUBSCRIBE]: '/unsubscribe/[token]/edit',
    [routing.EMERGENCY_PEOPLE]: '/profile/emergency_people/new',
    [routing.EXAMPLE]: '/example',
    [routing.FAQ]: '/faqs',
    [routing.FAQ_SEARCH]: '/faqs/search',
    [routing.FAQ_SHOW]: '/faqs/[slug]((?!search).*)',
    [routing.FORGOTTEN_PASSWORD]: '/utenti/password/nuovo',
    [routing.GIFTS]: '/wallet/gifts',
    [routing.GIFT_CONFIRMATION]: '/wallet/gifts/confirmation',
    [routing.GIFT_PENDING]: '/gifts/pending/[reference]',
    [routing.GIFT_RECIPIENT]: '/wallet/gifts/recipient',
    [routing.GIFT_SUBMIT]: '/wallet/gifts/submit',
    [routing.HOME]: '',
    [routing.HOME_BUY]: '/comprare',
    [routing.IMPRESSUM]: '/pages/impressum',
    [routing.INTERNAL_ERROR]: '/500',
    [routing.KYC_ANNUAL_INCOME]: '/utenti/annual_income',
    [routing.KYC_BUSINESS_SECTOR]: '/utenti/business_sector',
    [routing.KYC_COUNTRIES_OF_RESIDENCE]: '/utenti/residence',
    [routing.KYC_DATE_OF_BIRTH]: '/utenti/date_of_birth',
    [routing.KYC_DONE]: '/utenti/done_kyc',
    [routing.KYC_EXPOSED_PERSON]: '/utenti/exposed_person',
    [routing.KYC_FORECASTED_ANNUAL_INVESTMENTS]: '/utenti/forecasted_annual_investments',
    [routing.KYC_LOCATION]: '/utenti/location',
    [routing.KYC_NATIONALITIES]: '/utenti/nationalite',
    [routing.KYC_OCCUPATION]: '/utenti/occupation',
    [routing.KYC_PHONE_NUMBER]: '/utenti/phone_number',
    [routing.KYC_PHOTO_IDENTIFICATION]: '/utenti/photo_identification',
    [routing.KYC_SOURCES_OF_FUNDS]: '/utenti/sources_of_funds',
    [routing.MAINTENANCE]: '/maintenance',
    [routing.MOBILE_APP_REDIRECTION]: '/mobile-app',
    [routing.NOT_FOUND]: '/404',
    [routing.PAGES]: '/pages/[slug]',
    [routing.PASSWORD_SETTINGS]: '/profile/password-settings',
    [routing.PAYMENTS]: '/profile/payments',
    [routing.PRECIOUS_METAL_GUIDE]: '/guida-metalli-preziosi',
    [routing.PRECIOUS_METAL_GUIDE_LEVEL1]: '/guida-metalli-preziosi/[slug]',
    [routing.PRECIOUS_METAL_GUIDE_LEVEL2]: '/guida-metalli-preziosi/[slug]/[subSlug]',
    [routing.PRECIOUS_METAL_GUIDE_LEVEL3]: '/guida-metalli-preziosi/[slug]/[subSlug]/[subSubSlug]',
    [routing.PRICING]: '/tariffe',
    [routing.PRODUCT_SHOW]: '/comprare/[metal]/prodotto/[slug]',
    [routing.PROFILE]: '/profilo',
    [routing.PURCHASING_LIMIT]: '/purchasing_limit',
    [routing.REFERRALS]: '/referrals',
    [routing.REFERRALS_TERMS]: '/referrals/terms-and-conditions',
    [routing.REFERRAL]: '/r/[slug]',
    [routing.SAFERPAY_REDIRECTION]: '/check_card',
    [routing.SAFERPAY_TRANSACTION]: '/check_transaction',
    [routing.SAVING_ASSISTANT]: '/investire-metalli-preziosi-fisico',
    [routing.SEARCH]: '/search',
    [routing.SHIPPING_ADDRESS]: '/profile/address/shipping-address',
    [routing.SHOP_SUBCATEGORY]: '/comprare/[metal]/[slug]',
    [routing.SIGN_IN]: '/utenti/collegarsi',
    [routing.SIGN_UP]: '/utenti/iscriversi',
    [routing.SILVER_LANDING]: '/investimento-argenti-fisico-senza-IVA',
    [routing.STORAGE_FEES]: '/storage_fees/claims',
    [routing.STORAGE_SOLUTION]: '/stoccaggio-fisico-oro-svizzera',
    [routing.TUTORIAL_VIDEOS]: '/video-tutorial',
    [routing.TUTORIAL_VIDEO]: '/video-tutorial/[slug]',
    [routing.TWO_STEPS_AUTH_SMS]: '/profile/two-step-authentification-sms',
    [routing.TWO_STEPS_AUTH_TOTP]: '/profile/two-step-authentification-totp',
    [routing.UNLOCK_ACCOUNT]: '/users/unlock',
    [routing.VERIFY_AUTH]: '/users/verify_authy',
    [routing.WALLET]: '/wallet',
    [routing.WALLET_AUTO_SAVINGS]: '/wallet/auto_savings',
    [routing.WALLET_AUTO_SAVINGS_SHOW]: '/wallet/auto_savings/[id]',
    [routing.WALLET_CLAIMS]: '/dashboard/claims',
    [routing.WALLET_CLAIM_SHOW]: '/dashboard/claims/[id]',
    [routing.WALLET_GIFTS]: '/dashboard/gifts',
    [routing.WALLET_GIFT_SHOW]: '/dashboard/gifts/[reference_number]',
    [routing.WALLET_ORDERS]: '/dashboard/orders',
    [routing.WALLET_ORDER_SHOW]: '/dashboard/orders/[id]',
    [routing.WALLET_SELL]: '/wallet/sell_products/new',
    [routing.WALLET_SELL_REVIEW]: '/wallet/sell_products/process_submit',
    [routing.WALLET_SELL_THANKS]: '/wallet/sell_products/thanks',
    [routing.WISHLIST]: '/dashboard/wishlist',
    [routing.WITHDRAWAL]: '/profile/payments/withdraws/new',
  }),
  [FR_FR]: Object.freeze({
    [routing.ABANDONED_CART]: '/set_cart/[id]',
    [routing.ABOUT_US]: '/a-propos',
    [routing.ACCOUNT_TRANSACTIONS]: '/profile/account_transactions',
    [routing.ALERT_SETTINGS]: '/dashboard/alerts',
    [routing.AUTO_SAVINGS]: '/plan-epargne',
    [routing.BAD_REQUEST]: '/400',
    [routing.BILLING_ADDRESS]: '/profile/address/billing-address',
    [routing.BLOG_ARTICLE]: '/blog/[category]/[slug]',
    [routing.BLOG_CATEGORY]: '/blog/categories/[slug]',
    [routing.BLOG_HOME]: '/blog',
    [routing.BLOG_SEARCH]: '/blog/search',
    [routing.BLOG_TAG]: '/blog/tags/[slug]',
    [routing.BUY]: '/acheter/[metal]',
    [routing.CART]: '/cart',
    [routing.CHANGE_PASSWORD]: '/users/password/edit',
    [routing.CHARTS]: `/[metalSlug](${Object.values(EMetalSlug).join('|')})/[slug*]`,
    [routing.CHECKOUT_BILLING]: '/order/process_address',
    [routing.CHECKOUT_PAYMENT]: '/order/process_payment',
    [routing.CHECKOUT_REVIEW]: '/order/process_submit',
    [routing.CHECKOUT_SHIPPING]: '/order/storage_shipping',
    [routing.CHECKOUT_THANKS]: '/orders/[orderId]/thanks',
    [routing.CONTACT]: '/contact',
    [routing.CONTACT_FORM]: '/contact-formulaire',
    [routing.DELIVERY_CLAIMS_BILLING_ADDRESS]: '/delivery_claims/billing_address',
    [routing.DELIVERY_CLAIMS_BREAKDOWN]: '/delivery_claims/breakdown',
    [routing.DELIVERY_CLAIMS_DELIVERY]: '/delivery_claims/delivery',
    [routing.DELIVERY_CLAIMS_ORDER_REVIEW]: '/delivery_claims/process_submit',
    [routing.DELIVERY_CLAIMS_PAYMENT_METHOD]: '/delivery_claims/process_payment',
    [routing.DELIVERY_CLAIMS_THANK_YOU]: '/delivery_claims/[claimId]/thanks',
    [routing.DEPOSIT]: '/profile/payments/deposit',
    [routing.DETAILS_GIFT]: '/gifts/pending/[reference]/details',
    [routing.DONE_SIGN_UP]: '/compte/fini',
    [routing.EMAIL_UNSUBSCRIBE]: '/unsubscribe/[token]/edit',
    [routing.EMERGENCY_PEOPLE]: '/profile/emergency_people/new',
    [routing.EXAMPLE]: '/example',
    [routing.FAQ]: '/faqs',
    [routing.FAQ_SEARCH]: '/faqs/search',
    [routing.FAQ_SHOW]: '/faqs/[slug]((?!search).*)',
    [routing.FORGOTTEN_PASSWORD]: '/compte/mot-de-passe/nouveau',
    [routing.GIFTS]: '/wallet/gifts',
    [routing.GIFT_CONFIRMATION]: '/wallet/gifts/confirmation',
    [routing.GIFT_PENDING]: '/gifts/pending/[reference]',
    [routing.GIFT_RECIPIENT]: '/wallet/gifts/recipient',
    [routing.GIFT_SUBMIT]: '/wallet/gifts/submit',
    [routing.HOME]: '',
    [routing.HOME_BUY]: '/acheter',
    [routing.IMPRESSUM]: '/pages/impressum',
    [routing.INTERNAL_ERROR]: '/500',
    [routing.KYC_ANNUAL_INCOME]: '/compte/annual_income',
    [routing.KYC_BUSINESS_SECTOR]: '/compte/business_sector',
    [routing.KYC_COUNTRIES_OF_RESIDENCE]: '/compte/residence',
    [routing.KYC_DATE_OF_BIRTH]: '/compte/date_of_birth',
    [routing.KYC_DONE]: '/compte/fini_kyc',
    [routing.KYC_EXPOSED_PERSON]: '/compte/exposed_person',
    [routing.KYC_FORECASTED_ANNUAL_INVESTMENTS]: '/compte/forecasted_annual_investments',
    [routing.KYC_LOCATION]: '/compte/location',
    [routing.KYC_NATIONALITIES]: '/compte/nationalite',
    [routing.KYC_OCCUPATION]: '/compte/occupation',
    [routing.KYC_PHONE_NUMBER]: '/compte/phone_number',
    [routing.KYC_PHOTO_IDENTIFICATION]: '/compte/photo_identification',
    [routing.KYC_SOURCES_OF_FUNDS]: '/compte/sources_of_funds',
    [routing.MAINTENANCE]: '/maintenance',
    [routing.MOBILE_APP_REDIRECTION]: '/mobile-app',
    [routing.NOT_FOUND]: '/404',
    [routing.PAGES]: '/pages/[slug]',
    [routing.PASSWORD_SETTINGS]: '/profile/password-settings',
    [routing.PAYMENTS]: '/profile/payments',
    [routing.PRECIOUS_METAL_GUIDE]: '/guide-metaux-precieux',
    [routing.PRECIOUS_METAL_GUIDE_LEVEL1]: '/guide-metaux-precieux/[slug]',
    [routing.PRECIOUS_METAL_GUIDE_LEVEL2]: '/guide-metaux-precieux/[slug]/[subSlug]',
    [routing.PRECIOUS_METAL_GUIDE_LEVEL3]: '/guide-metaux-precieux/[slug]/[subSlug]/[subSubSlug]',
    [routing.PRICING]: '/tarifs',
    [routing.PRODUCT_SHOW]: '/acheter/[metal]/produit/[slug]',
    [routing.PROFILE]: '/profile',
    [routing.PURCHASING_LIMIT]: '/purchasing_limit',
    [routing.REFERRALS]: '/referrals',
    [routing.REFERRALS_TERMS]: '/referrals/terms-and-conditions',
    [routing.REFERRAL]: '/r/[slug]',
    [routing.SAFERPAY_REDIRECTION]: '/check_card',
    [routing.SAFERPAY_TRANSACTION]: '/check_transaction',
    [routing.SAVING_ASSISTANT]: '/investir-metaux-precieux-physique',
    [routing.SEARCH]: '/search',
    [routing.SHIPPING_ADDRESS]: '/profile/address/shipping-address',
    [routing.SHOP_SUBCATEGORY]: '/acheter/[metal]/[slug]',
    [routing.SIGN_IN]: '/compte/connexion',
    [routing.SIGN_UP]: '/compte/inscription',
    [routing.SILVER_LANDING]: '/investissement-argent-physique-sans-TVA',
    [routing.STORAGE_FEES]: '/storage_fees/claims',
    [routing.STORAGE_SOLUTION]: '/stockage-or-physique-suisse',
    [routing.TUTORIAL_VIDEOS]: '/tutoriels-video',
    [routing.TUTORIAL_VIDEO]: '/tutoriels-video/[slug]',
    [routing.TWO_STEPS_AUTH_SMS]: '/profile/two-step-authentification-sms',
    [routing.TWO_STEPS_AUTH_TOTP]: '/profile/two-step-authentification-totp',
    [routing.UNLOCK_ACCOUNT]: '/users/unlock',
    [routing.VERIFY_AUTH]: '/users/verify_authy',
    [routing.WALLET]: '/wallet',
    [routing.WALLET_AUTO_SAVINGS]: '/wallet/auto_savings',
    [routing.WALLET_AUTO_SAVINGS_SHOW]: '/wallet/auto_savings/[id]',
    [routing.WALLET_CLAIMS]: '/dashboard/claims',
    [routing.WALLET_CLAIM_SHOW]: '/dashboard/claims/[id]',
    [routing.WALLET_GIFTS]: '/dashboard/gifts',
    [routing.WALLET_GIFT_SHOW]: '/dashboard/gifts/[reference_number]',
    [routing.WALLET_ORDERS]: '/dashboard/orders',
    [routing.WALLET_ORDER_SHOW]: '/dashboard/orders/[id]',
    [routing.WALLET_SELL]: '/wallet/sell_products/new',
    [routing.WALLET_SELL_REVIEW]: '/wallet/sell_products/process_submit',
    [routing.WALLET_SELL_THANKS]: '/wallet/sell_products/thanks',
    [routing.WISHLIST]: '/dashboard/wishlist',
    [routing.WITHDRAWAL]: '/profile/payments/withdraws/new',
  }),
  [DE_DE]: Object.freeze({
    [routing.ABANDONED_CART]: '/set_cart/[id]',
    [routing.ABOUT_US]: '/uber-uns',
    [routing.ACCOUNT_TRANSACTIONS]: '/profile/account_transactions',
    [routing.ALERT_SETTINGS]: '/dashboard/alerts',
    [routing.AUTO_SAVINGS]: '/goldsparplan',
    [routing.BAD_REQUEST]: '/400',
    [routing.BILLING_ADDRESS]: '/profile/address/billing-address',
    [routing.BLOG_ARTICLE]: '/blog/[category]/[slug]',
    [routing.BLOG_CATEGORY]: '/blog/categories/[slug]',
    [routing.BLOG_HOME]: '/blog',
    [routing.BLOG_SEARCH]: '/blog/search',
    [routing.BLOG_TAG]: '/blog/tags/[slug]',
    [routing.BUY]: '/kaufen/[metal]',
    [routing.CART]: '/cart',
    [routing.CHANGE_PASSWORD]: '/users/password/edit',
    [routing.CHARTS]: `/[metalSlug](${Object.values(EMetalSlug).join('|')})/[slug*]`,
    [routing.CHECKOUT_BILLING]: '/order/process_address',
    [routing.CHECKOUT_PAYMENT]: '/order/process_payment',
    [routing.CHECKOUT_REVIEW]: '/order/process_submit',
    [routing.CHECKOUT_SHIPPING]: '/order/storage_shipping',
    [routing.CHECKOUT_THANKS]: '/orders/[orderId]/thanks',
    [routing.CONTACT]: '/kontakt',
    [routing.CONTACT_FORM]: '/kontakt-formular',
    [routing.DELIVERY_CLAIMS_BILLING_ADDRESS]: '/delivery_claims/billing_address',
    [routing.DELIVERY_CLAIMS_BREAKDOWN]: '/delivery_claims/breakdown',
    [routing.DELIVERY_CLAIMS_DELIVERY]: '/delivery_claims/delivery',
    [routing.DELIVERY_CLAIMS_ORDER_REVIEW]: '/delivery_claims/process_submit',
    [routing.DELIVERY_CLAIMS_PAYMENT_METHOD]: '/delivery_claims/process_payment',
    [routing.DELIVERY_CLAIMS_THANK_YOU]: '/delivery_claims/[claimId]/thanks',
    [routing.DEPOSIT]: '/profile/payments/deposit',
    [routing.DETAILS_GIFT]: '/gifts/pending/[reference]/details',
    [routing.DONE_SIGN_UP]: '/benutzer/done',
    [routing.EMAIL_UNSUBSCRIBE]: '/unsubscribe/[token]/edit',
    [routing.EMERGENCY_PEOPLE]: '/profile/emergency_people/new',
    [routing.EXAMPLE]: '/example',
    [routing.FAQ]: '/faqs',
    [routing.FAQ_SEARCH]: '/faqs/search',
    [routing.FAQ_SHOW]: '/faqs/[slug]((?!search).*)',
    [routing.FORGOTTEN_PASSWORD]: '/benutzer/passwort/neu',
    [routing.GIFTS]: '/wallet/gifts',
    [routing.GIFT_CONFIRMATION]: '/wallet/gifts/confirmation',
    [routing.GIFT_PENDING]: '/gifts/pending/[reference]',
    [routing.GIFT_RECIPIENT]: '/wallet/gifts/recipient',
    [routing.GIFT_SUBMIT]: '/wallet/gifts/submit',
    [routing.HOME]: '',
    [routing.HOME_BUY]: '/kaufen',
    [routing.IMPRESSUM]: '/pages/impressum',
    [routing.INTERNAL_ERROR]: '/500',
    [routing.KYC_ANNUAL_INCOME]: '/benutzer/annual_income',
    [routing.KYC_BUSINESS_SECTOR]: '/benutzer/business_sector',
    [routing.KYC_COUNTRIES_OF_RESIDENCE]: '/benutzer/residence',
    [routing.KYC_DATE_OF_BIRTH]: '/benutzer/date_of_birth',
    [routing.KYC_DONE]: '/benutzer/fini',
    [routing.KYC_EXPOSED_PERSON]: '/benutzer/exposed_person',
    [routing.KYC_FORECASTED_ANNUAL_INVESTMENTS]: '/benutzer/forecasted_annual_investments',
    [routing.KYC_LOCATION]: '/benutzer/location',
    [routing.KYC_NATIONALITIES]: '/benutzer/nationality',
    [routing.KYC_OCCUPATION]: '/benutzer/occupation',
    [routing.KYC_PHONE_NUMBER]: '/benutzer/phone_number',
    [routing.KYC_PHOTO_IDENTIFICATION]: '/benutzer/photo_identification',
    [routing.KYC_SOURCES_OF_FUNDS]: '/benutzer/sources_of_funds',
    [routing.MAINTENANCE]: '/maintenance',
    [routing.MOBILE_APP_REDIRECTION]: '/mobile-app',
    [routing.NOT_FOUND]: '/404',
    [routing.PAGES]: '/pages/[slug]',
    [routing.PASSWORD_SETTINGS]: '/profile/password-settings',
    [routing.PAYMENTS]: '/profile/payments',
    [routing.PRECIOUS_METAL_GUIDE]: '/edelmetall-leitfaden',
    [routing.PRECIOUS_METAL_GUIDE_LEVEL1]: '/edelmetall-leitfaden/[slug]',
    [routing.PRECIOUS_METAL_GUIDE_LEVEL2]: '/edelmetall-leitfaden/[slug]/[subSlug]',
    [routing.PRECIOUS_METAL_GUIDE_LEVEL3]: '/edelmetall-leitfaden/[slug]/[subSlug]/[subSubSlug]',
    [routing.PRICING]: '/preise',
    [routing.PRODUCT_SHOW]: '/kaufen/[metal]/produkt/[slug]',
    [routing.PROFILE]: '/deusttprofil',
    [routing.PURCHASING_LIMIT]: '/purchasing_limit',
    [routing.REFERRALS]: '/referrals',
    [routing.REFERRALS_TERMS]: '/referrals/terms-and-conditions',
    [routing.REFERRAL]: '/r/[slug]',
    [routing.SAFERPAY_REDIRECTION]: '/check_card',
    [routing.SAFERPAY_TRANSACTION]: '/check_transaction',
    [routing.SAVING_ASSISTANT]: '/investieren-edelmetalle-physisches',
    [routing.SEARCH]: '/search',
    [routing.SHIPPING_ADDRESS]: '/profile/address/shipping-address',
    [routing.SHOP_SUBCATEGORY]: '/kaufen/[metal]/[slug]',
    [routing.SIGN_IN]: '/benutzer/einloggen',
    [routing.SIGN_UP]: '/benutzer/anmelden',
    [routing.SILVER_LANDING]: '/investieren-physisches-silber-mwst-frei',
    [routing.STORAGE_FEES]: '/storage_fees/claims',
    [routing.STORAGE_SOLUTION]: '/lagerung-physische-gold-schweiz',
    [routing.TUTORIAL_VIDEOS]: '/tutorial-videos',
    [routing.TUTORIAL_VIDEO]: '/tutorial-videos/[slug]',
    [routing.TWO_STEPS_AUTH_SMS]: '/profile/two-step-authentification-sms',
    [routing.TWO_STEPS_AUTH_TOTP]: '/profile/two-step-authentification-totp',
    [routing.UNLOCK_ACCOUNT]: '/users/unlock',
    [routing.VERIFY_AUTH]: '/users/verify_authy',
    [routing.WALLET]: '/wallet',
    [routing.WALLET_AUTO_SAVINGS]: '/wallet/auto_savings',
    [routing.WALLET_AUTO_SAVINGS_SHOW]: '/wallet/auto_savings/[id]',
    [routing.WALLET_CLAIMS]: '/dashboard/claims',
    [routing.WALLET_CLAIM_SHOW]: '/dashboard/claims/[id]',
    [routing.WALLET_GIFTS]: '/dashboard/gifts',
    [routing.WALLET_GIFT_SHOW]: '/dashboard/gifts/[reference_number]',
    [routing.WALLET_ORDERS]: '/dashboard/orders',
    [routing.WALLET_ORDER_SHOW]: '/dashboard/orders/[id]',
    [routing.WALLET_SELL]: '/wallet/sell_products/new',
    [routing.WALLET_SELL_REVIEW]: '/wallet/sell_products/process_submit',
    [routing.WALLET_SELL_THANKS]: '/wallet/sell_products/thanks',
    [routing.WISHLIST]: '/dashboard/wishlist',
    [routing.WITHDRAWAL]: '/profile/payments/withdraws/new',
  }),
  [EN_US]: Object.freeze({
    [routing.ABANDONED_CART]: '/set_cart/[id]',
    [routing.ABOUT_US]: '/about-us',
    [routing.ACCOUNT_TRANSACTIONS]: '/profile/account_transactions',
    [routing.ALERT_SETTINGS]: '/dashboard/alerts',
    [routing.AUTO_SAVINGS]: '/auto-savings',
    [routing.BAD_REQUEST]: '/400',
    [routing.BILLING_ADDRESS]: '/profile/address/billing-address',
    [routing.BLOG_ARTICLE]: '/blog/[category]/[slug]',
    [routing.BLOG_CATEGORY]: '/blog/categories/[slug]',
    [routing.BLOG_HOME]: '/blog',
    [routing.BLOG_SEARCH]: '/blog/search',
    [routing.BLOG_TAG]: '/blog/tags/[slug]',
    [routing.BUY]: '/buy/[metal]',
    [routing.CART]: '/cart',
    [routing.CHANGE_PASSWORD]: '/users/password/edit',
    [routing.CHARTS]: `/[metalSlug](${Object.values(EMetalSlug).join('|')})/[slug*]`,
    [routing.CHECKOUT_BILLING]: '/order/process_address',
    [routing.CHECKOUT_PAYMENT]: '/order/process_payment',
    [routing.CHECKOUT_REVIEW]: '/order/process_submit',
    [routing.CHECKOUT_SHIPPING]: '/order/storage_shipping',
    [routing.CHECKOUT_THANKS]: '/orders/[orderId]/thanks',
    [routing.CONTACT]: '/contact',
    [routing.CONTACT_FORM]: '/contact-form',
    [routing.DELIVERY_CLAIMS_BILLING_ADDRESS]: '/delivery_claims/billing_address',
    [routing.DELIVERY_CLAIMS_BREAKDOWN]: '/delivery_claims/breakdown',
    [routing.DELIVERY_CLAIMS_DELIVERY]: '/delivery_claims/delivery',
    [routing.DELIVERY_CLAIMS_ORDER_REVIEW]: '/delivery_claims/process_submit',
    [routing.DELIVERY_CLAIMS_PAYMENT_METHOD]: '/delivery_claims/process_payment',
    [routing.DELIVERY_CLAIMS_THANK_YOU]: '/delivery_claims/[claimId]/thanks',
    [routing.DEPOSIT]: '/profile/payments/deposit',
    [routing.DETAILS_GIFT]: '/gifts/pending/[reference]/details',
    [routing.DONE_SIGN_UP]: '/users/done_sign_up',
    [routing.EMAIL_UNSUBSCRIBE]: '/unsubscribe/[token]/edit',
    [routing.EMERGENCY_PEOPLE]: '/profile/emergency_people/new',
    [routing.EXAMPLE]: '/example',
    [routing.FAQ]: '/faqs',
    [routing.FAQ_SEARCH]: '/faqs/search',
    [routing.FAQ_SHOW]: '/faqs/[slug]((?!search).*)',
    [routing.FORGOTTEN_PASSWORD]: '/users/password/new',
    [routing.GIFTS]: '/wallet/gifts',
    [routing.GIFT_CONFIRMATION]: '/wallet/gifts/confirmation',
    [routing.GIFT_PENDING]: '/gifts/pending/[reference]',
    [routing.GIFT_RECIPIENT]: '/wallet/gifts/recipient',
    [routing.GIFT_SUBMIT]: '/wallet/gifts/submit',
    [routing.HOME]: '',
    [routing.HOME_BUY]: '/buy',
    [routing.IMPRESSUM]: '/pages/impressum',
    [routing.INTERNAL_ERROR]: '/500',
    [routing.KYC_ANNUAL_INCOME]: '/users/annual_income',
    [routing.KYC_BUSINESS_SECTOR]: '/users/business_sector',
    [routing.KYC_COUNTRIES_OF_RESIDENCE]: '/users/residence',
    [routing.KYC_DATE_OF_BIRTH]: '/users/date_of_birth',
    [routing.KYC_DONE]: '/users/done',
    [routing.KYC_EXPOSED_PERSON]: '/users/exposed_person',
    [routing.KYC_FORECASTED_ANNUAL_INVESTMENTS]: '/users/forecasted_annual_investments',
    [routing.KYC_LOCATION]: '/users/location',
    [routing.KYC_NATIONALITIES]: '/users/nationality',
    [routing.KYC_OCCUPATION]: '/users/occupation',
    [routing.KYC_PHONE_NUMBER]: '/users/phone_number',
    [routing.KYC_PHOTO_IDENTIFICATION]: '/users/photo_identification',
    [routing.KYC_SOURCES_OF_FUNDS]: '/users/sources_of_funds',
    [routing.MAINTENANCE]: '/maintenance',
    [routing.MOBILE_APP_REDIRECTION]: '/mobile-app',
    [routing.NOT_FOUND]: '/404',
    [routing.PAGES]: '/pages/[slug]',
    [routing.PASSWORD_SETTINGS]: '/profile/password-settings',
    [routing.PAYMENTS]: '/profile/payments',
    [routing.PRECIOUS_METAL_GUIDE]: '/precious-metals-guide',
    [routing.PRECIOUS_METAL_GUIDE_LEVEL1]: '/precious-metals-guide/[slug]',
    [routing.PRECIOUS_METAL_GUIDE_LEVEL2]: '/precious-metals-guide/[slug]/[subSlug]',
    [routing.PRECIOUS_METAL_GUIDE_LEVEL3]: '/precious-metals-guide/[slug]/[subSlug]/[subSubSlug]',
    [routing.PRICING]: '/pricing',
    [routing.PRODUCT_SHOW]: '/buy/[metal]/product/[slug]',
    [routing.PROFILE]: '/profile',
    [routing.PURCHASING_LIMIT]: '/purchasing_limit',
    [routing.REFERRALS]: '/referrals',
    [routing.REFERRALS_TERMS]: '/referrals/terms-and-conditions',
    [routing.REFERRAL]: '/r/[slug]',
    [routing.SAFERPAY_REDIRECTION]: '/check_card',
    [routing.SAFERPAY_TRANSACTION]: '/check_transaction',
    [routing.SAVING_ASSISTANT]: '/investment-physical-precious-metals',
    [routing.SEARCH]: '/search',
    [routing.SHIPPING_ADDRESS]: '/profile/address/shipping-address',
    [routing.SHOP_SUBCATEGORY]: '/buy/[metal]/[slug]',
    [routing.SIGN_IN]: '/users/sign_in',
    [routing.SIGN_UP]: '/users/sign_up',
    [routing.SILVER_LANDING]: '/physical-silver-investing-vat-free',
    [routing.STORAGE_FEES]: '/storage_fees/claims',
    [routing.STORAGE_SOLUTION]: '/physical-gold-storage-switzerland',
    [routing.TUTORIAL_VIDEOS]: '/tutorial-videos',
    [routing.TUTORIAL_VIDEO]: '/tutorial-videos/[slug]',
    [routing.TWO_STEPS_AUTH_SMS]: '/profile/two-step-authentification-sms',
    [routing.TWO_STEPS_AUTH_TOTP]: '/profile/two-step-authentification-totp',
    [routing.UNLOCK_ACCOUNT]: '/users/unlock',
    [routing.VERIFY_AUTH]: '/users/verify_authy',
    [routing.WALLET]: '/wallet',
    [routing.WALLET_AUTO_SAVINGS]: '/wallet/auto_savings',
    [routing.WALLET_AUTO_SAVINGS_SHOW]: '/wallet/auto_savings/[id]',
    [routing.WALLET_CLAIMS]: '/dashboard/claims',
    [routing.WALLET_CLAIM_SHOW]: '/dashboard/claims/[id]',
    [routing.WALLET_GIFTS]: '/dashboard/gifts',
    [routing.WALLET_GIFT_SHOW]: '/dashboard/gifts/[reference_number]',
    [routing.WALLET_ORDERS]: '/dashboard/orders',
    [routing.WALLET_ORDER_SHOW]: '/dashboard/orders/[id]',
    [routing.WALLET_SELL]: '/wallet/sell_products/new',
    [routing.WALLET_SELL_REVIEW]: '/wallet/sell_products/process_submit',
    [routing.WALLET_SELL_THANKS]: '/wallet/sell_products/thanks',
    [routing.WISHLIST]: '/dashboard/wishlist',
    [routing.WITHDRAWAL]: '/profile/payments/withdraws/new',
  }),
});
