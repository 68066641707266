import { AxiosInstance } from 'axios';

export const ID_FORMAT = ':id';

export const setIdUrl = (url: string, query = ID_FORMAT, subUrl?: string): string =>
  `${url}/${query}${subUrl ? '/' + subUrl : ''}`;

export const replaceIdUrl = (url: string, id: string | number, query = ID_FORMAT): string =>
  url.replace(query, `${id}`);

export const replaceAPIVersion = ({
  axiosInstance,
  apiVersion,
}: {
  axiosInstance: AxiosInstance;
  apiVersion: string;
}) => {
  return axiosInstance.defaults.baseURL.replace(/\/v\d+$/, `/${apiVersion}`);
};
