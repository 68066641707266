export enum EFilenameImageBlur {
  'about-us/cracco.jpg' = 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD//gAfQ29tcHJlc3NlZCBieSBqcGVnLXJlY29tcHJlc3P/2wCEABsSFBcUERsXFhceHBsgKEIrKCUlKFE6PTBCYFVlZF9VXVtqeJmBanGQc1tdhbWGkJ6jq62rZ4C8ybqmx5moq6QBHB4eKCMoTisrTqRuXW6kpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpP/AABEIAAoACgMBEQACEQEDEQH/xAGiAAABBQEBAQEBAQAAAAAAAAAAAQIDBAUGBwgJCgsQAAIBAwMCBAMFBQQEAAABfQECAwAEEQUSITFBBhNRYQcicRQygZGhCCNCscEVUtHwJDNicoIJChYXGBkaJSYnKCkqNDU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6g4SFhoeIiYqSk5SVlpeYmZqio6Slpqeoqaqys7S1tre4ubrCw8TFxsfIycrS09TV1tfY2drh4uPk5ebn6Onq8fLz9PX29/j5+gEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoLEQACAQIEBAMEBwUEBAABAncAAQIDEQQFITEGEkFRB2FxEyIygQgUQpGhscEJIzNS8BVictEKFiQ04SXxFxgZGiYnKCkqNTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqCg4SFhoeIiYqSk5SVlpeYmZqio6Slpqeoqaqys7S1tre4ubrCw8TFxsfIycrS09TV1tfY2dri4+Tl5ufo6ery8/T19vf4+fr/2gAMAwEAAhEDEQA/AL96VaUOJfuY6H7tJ3KVrEwuMjORQI5y3J+xz89/6GtWQijub+8fzoA//9k=',
  'about-us/omar.jpg' = 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4QCARXhpZgAASUkqAAgAAAAEABoBBQABAAAAPgAAABsBBQABAAAARgAAACgBAwABAAAAAgAAAGmHBAABAAAATgAAAAAAAABIAAAAAQAAAEgAAAABAAAAAwAAkAcABAAAADAyMTAAoAcABAAAADAxMDABoAMAAQAAAP//AAAAAAAA/9sAhAAbEhQXFBEbFxYXHhwbIChCKyglJShROj0wQmBVZWRfVV1baniZgWpxkHNbXYW1hpCeo6utq2eAvMm6pseZqKukARweHigjKE4rK06kbl1upKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKSkpKT/wAARCAAKAAoDAREAAhEBAxEB/8QBogAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoLEAACAQMDAgQDBQUEBAAAAX0BAgMABBEFEiExQQYTUWEHInEUMoGRoQgjQrHBFVLR8CQzYnKCCQoWFxgZGiUmJygpKjQ1Njc4OTpDREVGR0hJSlNUVVZXWFlaY2RlZmdoaWpzdHV2d3h5eoOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4eLj5OXm5+jp6vHy8/T19vf4+foBAAMBAQEBAQEBAQEAAAAAAAABAgMEBQYHCAkKCxEAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwCy7xyyibc2z17Chxd7DTVi6Jo8D5x+dOzJuZV1xpEOOOa2j8Rm9jPyfU1qSf/Z',
  'card/article-default.png' = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAUElEQVR4AY3BQQ6AIAxFwcenCfc/qnFFpMXEnQ1RZsp5HpdqMz6E9yHVZvxQbSY2icQj8AgyI5kxeYgXI5msGYlUWDGSKrEiNim8D36E93EDaPMZ6U/0gKYAAAAASUVORK5CYII=',
  'auto-savings/calendar-header.png' = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAABfElEQVR4AW3BT0sUYRwH8O8z85t5dnZ0d9XYP6ZhikJgBoIUKFG3IvDgKQjqHUhvwTfgSejuwYuiN88aIpK2rgehQ0uLGpu11Lo7szPP88zM03j382G4w87WBvvTaizPLDx6q/52xMnRr4+E1NlpDbVaNSPj2HAEyeumZ5QqmZmKt/fMyHH5JRocIaQ+Hx5kLyBWvH5z+Lcf+YzAPpSSecaARIQII84IqX8icIdmJ5b4sD0+CI1btn8O2QlANgFMg5DqZ1SYo1JuYmgaGduCkBJx3MJN6xs009BmD/Rpba2sIv18ZHS0T8kYQSARCoU+YwBdetA5vDDrXle3jXOK3m+/dFZvMowHQsF1bJBpIl9+gcv202r3O3uXvWdXKduW1+Oe43Fm5IoDLogMZLkFJcPEdbjM5wuNh1OTAVW4uXn/a8s8/rH/KgyVbVoGdBLroNtp+ircddycWnzzGjT9+Env6mdzvd6o7zCpLCEJVtIDL+RluVL0i6WxCKn/J8+geWFaiDgAAAAASUVORK5CYII=',
  'card/product-default.png' = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAdUlEQVR4AW3BwRIBQRBEwVc9w8H/f6sDtooRsaEXmbIdbze2+xViDlSM04UaZ4qXxBDzlpCEt5jELJMmMd4eLDUmUrErmtgkJjGx6YpGEjtJdJMmgCRABBAfxQ+xiKNJUzWIikUS3eSLJP4pXqQBKn6okAbLE+/XMBrc8vfwAAAAAElFTkSuQmCC',
  'charts/content-block-metals.png' = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAABdklEQVR4AV3BPy/EUAAA8Nd379q+p9deKefugkF0Qo8YJGI0IMIHQCLxAYTRVzARIiFhM1gIEpMY/Il/g4jKJRaJnJPSq7bX0ry2JsnF78dsra3j51drDGaDYafK+0Ilvsi0ioczk+OWIOXAH/RpW3mYpzOzPS9DKMkzyyeq9mZ/3ZcizwI14LdH1bZmQ6WBk4hCN34KQFlkUSWDBFALGQFXGGkyG6OfEOiUr3ZY9JqI0NreOBBXllaBks9U+7v6Q5TgQvjm+baX8KOzu9ythJ3bUiU1kc4ZY8kYxA+6u+NUnWOUJdzm5WnfHcWMzIaBThmc7lGf5tOur0lpge5HLY5tuOfID5wsrkODPOFzxnsFc3z8YJjsjQ+95KPZ/oJK4X5Te4ONCCaLoiiOplIptqBpA0cHewuy3L345QIpIVGns9BiDvR2UyTLslosFllCCIMxZjgshPX1xJyem/4ANVC5XF5TFGUKQpjUdX1XUZQrTdMi8M8vcpueDI7FRNsAAAAASUVORK5CYII=',
  'faq/gold.png' = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAABPElEQVR4AZXBTUvCcAAH4N+2dFZqShmp6DqlXoLyYoc8SNGtDxB9hKK6dO+TBB2EOgQVRUGXwl6slDQ6GDqxzAXD0ijd9t9cE3bw2vNQ6HF2fES98BVPW5NYl9MjcuN+aSExhy4GPaamowGRwrZvjKyW661ovSjUNlbWasm9JGiYDk5PIDQxuxSvxhdDYng98blM3OxmvpAZgKEPpnKBZ51eZX5Ybds0XQFFsbB1AnKl+KrDQMNEpJaXG+3EiCJTGiHQKUZ5f+NS2aeCBAMNQ/6uhOY3MzkRbAQ0VYMia8i0+gW19HudfrjqwEDDQA/KaFsUxipWtLpQBVEU/Sbru61+XfK6rqOLhiESCcPvIBf76djWo33k/N7tSj1/qDs5fvcHJgomp92KABey0I7gEDsj2xqHObFYEmX81x9axIqezNI1CwAAAABJRU5ErkJggg==',
  'homepage/logos.png' = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAABmklEQVR4AQ3Bz2/SUBwA8O/79r1SaJEy0G44NyHoxMDBqyZGvbid1BBNjNlhFz2aeFi8mfjP+E94MtlBjXES6fyRIkiGnR2jA0qh7XtPPx+yt/cBuk4b+72uPk9Y9Xyp8EpI4K7rvUaRdBrl1eD+zlOhPGw2wf5pazGQUjajPXvU3NreqDVqB3Y7DCh+64eT2e7z3QTdww6JI2qoNFMhRq4uMQ2IFISWbeiEVWHOsq43INSPQhye0ktLFG/0Wvt4eK0heBKDc9CizFy5mVVgNBlPhzRZADKV5iq1jXNfHQdfPN4+llJC/tZd+vJ6ufjm468zCcRIkSEo8/7k07v+MOEcWhzn8N9tVGD/Szu0VF2oAoEyZMJMZwbOKf9dr5h+11nLSSmhdtE6/nw0sUu5pJMuaoLqGSqnzBin+B+PK8rgyYOtyPfH6my0OPFHUWxaS+MVqgi6bJWFN3wfWFD4YbeOCm/FSDcCjyzniVu9fKWd0qLAurAuqWnmoXR2bfF91unVrxbDOykjj2Qd/k6nJ1wKr2qtLu5tbsI/b1O5vnhTTm0AAAAASUVORK5CYII=',
  'homepage/pamp.png' = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAABjElEQVR4AS3Bv2sTUQAH8O9797zrXe7dnfnR/LCN2iIFHZxEHKwiqYO4Brp0c3DS/hVOpaB065zVQbC6tEtBEKp0UKw5rSDGKumPy0vudy7PG/x8CHK773bhdg/pUJxZlGpXRJjeCKMYZa5trq4+SZEjGy+eT7u94OnfU//WwB9f5VNKSdcos23eb/Bkcf76wkH7YRtsGIT8w9f+48GZV1RVhqmaiTgh+HM8LJ2/Vr/z2z3qAphQgPSqRW0vSlK079fw7NEEy0sOpJT0SCQPPD/SkKOWXYgv1czXVFHk5+8+klBg1jlGyTEgguwmQzaz9WYHbO7ivBTe/rbO7cFeVzgv33ME8Tn0RQwR+ZWZav32oXvwTel0OlhqtUYnweTej97p5Y9uBPdXhFEYoy8CAlUfXzDxiiJnGE7UrOpbkyyTdRuoWgDXkVkW94hMC2MpTYbc7EJTisB7O9esrJQNdtLg/FPFVvcLUn4hjv5T1TOP4L+19TUl9OW0SlhKTDYyGE2dYjmrNepo3V3EP8Wdp+WuyEz7AAAAAElFTkSuQmCC',
  'maintenance/maintenance.png' = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAABgUlEQVR4AQXBu27TUBgA4P8/N5/ajtskbtJQlIQWpIgBFhiQACEhdgbegFdCSAxsDIh3YGBATAwRFAZSpJJiQkpL4uDjy7nxffji2csoOz2Z8M/vrif3eZSV9xpxljlo/oF3DORg5MzuyLM/5wuSJDxf3X00XSo03FeGd4eoogDjZI3Vb8p3THXAlCvbNbaIrMolIXSDNMRS17K1mXMTd4lMpMDANCRt7HDPqpRazjrbqRaR1FpG8GR6k9x+s8KtvICeUZbl6AnLBDnf1xLyvy2sEC7agr6+YzTxlyx4bTbALAMWzKmwcbcBLn65VFGAMLA1ccUqvtxR9KIQzq01EyJeznuFffq23G6d/YRVwNws7dDZg55KHFOFIIDzzLLJeL/GH7P6CEv/6eDQTtupe370UbyveqFtjk3SGDqLriqSXhlYxKD8cGvc3CjW7PHpd/FqNLRt4XQ/6wOc7Pg9US3Y4Xjiv375VmlYbI4fDhj1SBixhQSf+2u8DlkfdsMt/x/NlbiQFnkYWAAAAABJRU5ErkJggg==',
  'product/flag-ch.png' = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAABgUlEQVR4AQ3B7U6bUBgA4HPgBYFi2VKoU9puRRMdJssSm2jN4j14e96C12GyGH+MWZr9aZdK0xZJa5Gvcjicsz0PDmYz9Oz7YhKGMEsSiREiI1HcI5qGxcWiNCwrG97clPDkeeCPRjpnzEwATHW7/ZiF4f4WY3Z8draKVqup7/sRLKZTiVbVQRTH3y+OjtzBcGiTZrNZA2R39/ee4zh5GkUxVJRCoestu90+PzHNH+/L5Wf88qLsdD28ur6Ofz88/CKnp6LwmqaC6HnqrW1b8nrd7WVZp0+I9aEsDcmytDwMgRWFACBJ6A0hvMYY7zGGBErZfzWX5ZLnOYFGg9Wcc2ioCktcd/c4n79dGsYy4pzIADvFsoKf4/HcPHfTNkU16CBQI843483mj+44vCNL6mOcvD//nYTfsDjBmvaqdg8r+NLpVZuShj0sPE2ydDKSJYxqlg/2WylFRSIJKO47/QrcwUWNsRjPgqAoSRWYWcHtwwPaUA2qKDVrfeqyryfH/B+Hxc0mcNQIlgAAAABJRU5ErkJggg==',
  'homepage/silver-1kilo.png' = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAABbklEQVR4AS3By0oCURgA4P/8c5xxmpOKpqYZRZF0pUUQ1LpNixZFyy5EL9G2dwifIBLaRIugC9WibdCmkkjKRjOjq441zsyZYyP0fQT+HRwfkpKuTwWCqVniVp9+6r85wzAeAqHQWyQS5hT+3V3fsJ6Boc3eZHyG0q6mX5Edh7sf2d39jc/3l20Ez9nROXzVzbHBdP8EYwxUVSVAUHa4G2zaFto1kCh4bvP3UiiemAPOgwIRWoQQoOt6AahziVo7p+Cp1946+sYnZy3LQvAIIUB4irp+oUhtxWSqs4m7O1n4Fc3pnmgozTkH27YBEYG7olYuvZxoiD8rS8uAhXJFTiW65+1GQ0VEQEQwTRPy+cccSuqVFotw8FBumxq4TrJYKllM02TGGBFC8NfXyin1YyUajUELRQrV8rO+pqj+UR9VRnzUN4wUqWF876lMaywuLkALAc9WJgNVbhPhOhJxCQWrgUSmVmc44a6vr0LLHw9EoKcKMmHUAAAAAElFTkSuQmCC',
  'product/rocket.png' = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAABo0lEQVR4AS3B207aYAAA4JaesCB/phTo1g7oxBEV8RQnSzaTJbswXuwhFl/DV9gb7AV2sSjxZtmFF2aBGKOJGC3KSUuLwl+gQv8eoGwX+z4c+68Du1jx5MwnX5fYPjJjDOOT2n1DqgVpf8aBENf0R0ytdXBNk4nSZTVoOZaIBk/LLB9ZXZEESSgMnH0wVSZ/539RrYcm2/ccEJgQggGV7LvtT1uU7i1Zj+6L0YePSvTn8J5s1GsRzEOCgYckGKbeft78koFNe8HuKtzsTHCgNsttP39QJ3u0O5dwX67kwnqWUOnUqa6JQ8ID4cCUGRXFyuHRj3OeS5XI1wMQfc+dJtVOa77X4uJoEguxLECJeLJyLBcLgHtV9MajO189EMaBggh6ESfAtI6JXRm9qZZUTYMXluUUCNe+jkaSkKTM6njUp13TYp79Kb0VUfXxjOrVjhTmMiVmyjFhtpPLrTnkiK675zymp7/7bsGeTRg70P6mPTfmmd6NO+y1ExtZO535OiHXGcP4E2MqV7v4Q6iMOTKynDiF2sgyG3E+P0zP5SfYP38BGBrQSnGJJDUAAAAASUVORK5CYII=',
  'product/stopwatch.png' = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAABpUlEQVR4AQGaAWX+AfHx8QDf3OABvsbACfDw8BoKBQgfAQD/AfX4+uIWDArmFR0i+BggGv0DQFZMAsjCyAz29fEf+ez1NLrPxTnZ4NwiHgsYCQENCALm5t8A7evn/AMuKCsK+Pb1I+XT3j/rE/ZFi4KINk5NSSTa6NEZycTFDhkXHP76+PzwAzs6Ohv04u4640XySY9ZiTb/9vsdAPn/Ey4mNhUTUxUNzKXJ8g4dFuMCCwgIF8YAxSxnSG0q/fv6GAQCAAoEAf8K+fn2FD72QB4IJQwcEQEGDwQA+gQN6ScGEwUA+A0CAQIFAgQEAQEBAQD/AgMDBQQCCAgRDwz09gAJAgEG+/oL6vP3/QH9+/8BAf4CBAQAAwQFAAQEBP4CBAX65PLn9wgA/PoC9Pr56zXeM97N3sPiAAED8vn8/fv6/v77AgQF8qburOMJ1v/d9wL+6wNGREb+EBkO/8KXwgTkK9cC/x07+e0FC+2y4pfbvny1xzY1NcUEAQXdAaWoowTf190V/wcDKdXP1S0A/OUcAAUCAAH/F+UuLTDSAPr91yIvHevMVbZ/CZTFRQAAAABJRU5ErkJggg==',
  'product/fire.png' = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAABkklEQVR4AQXB607aUAAA4J7TyzmnPb0ICCsTldZkGiEbSxbjlkyzP+MN9g57QrP9BePmBZeYeIkBosaCAi0tlLb0su8D7thiRoMRaB93uN7TXYElcjWJ3HyK6ASGYa+yufHa/N5cst++NmGr1RZe5iMKANqY9awPm0m1njxYmr+KfN9ZTLEkReynj3uC43g0A2F5+TKpvz84/Pxvf9CoNHYV98xyOAyGGMcedP0phnCZjykxjNnW7tC42sn656azODVrUskYC6l+fz8SIRtncoiZCv7lbi9+iDvE65a3Y0fl7cfVwRdqFF9DIw3mBRhnkSr3gan/rNVvtROTZFPtnQKRGjmax1xU1ZxuMpgvQqgSxJNcob/eqVBvWFoTBfFtUYVUgYhlbFkiSE6YCHOplwWJH84VjJcspQwv5YAHhDQEbqCJBWd2NBuTMudxCUpte83u7h01bgb7utp95nUGoHS+klpvfsuXp/LFtRCQIZSg5PJQ7LZu2p31oNaJffk6CfJXJTv35+Su/VcQxFtlRZr8B/uPtUIsTA6oAAAAAElFTkSuQmCC',
  'shop/precious-metal-silver.png' = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAABPUlEQVR4ARXBQUsCQRgG4He+2V01UJfFzEzCkyevIkIEe/Qv9Nv6EcGCZ+kidTG6ePDQoVoDBTdbmnb0m2n2eQSc1WqFJEkipdTN4XAYMDM1Go33arX6GMdxOplMrAcnyzKhlOqmaXo3nU5v6/U6zWazF9/3P5Ik+QLABCfPcxBRNQzD8+FweNHr9drj8bitlDpbLpcoEZw8zxEEAaSUsNbCGINarYbj8SgclAjOb6GgtYaUEtZaWGtRqVSglMJgMECJ4BT5H3zPAxEJB0II+L4PZkYURSgRnHanC0EEIrLMDGaGlBKO1Vqj5MHJf/YwxtggCExRFIaZxel0Yiml2e12KBGcztWl1Vp/Z1n2mqbp03a7fV4sFstWq7WL49jC8eD0r/tYd9efm83mfj6fPwgnDMN9s9l8G41GBs4/MPaVlChO48QAAAAASUVORK5CYII=',
  'shop/precious-metal-gold.png' = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAABgklEQVR4AR3BTU8TQRgA4Pedmc7a7exC3SVALHAxJiZEeqioNJoQ48GrB/+aF+NfMB40GBNOBhKjFSQVTK0Y3Aoal6V1l/2ceSU+D/Y+7sHGqxfNP1HWfbDafui05q8iICbfDofPP+xuLM7PvF1du3Uqvh+GSNyabrl8xVlM7jXF6yUEgLnbrVayufA7V6f7wcFJxKLJEUKcXXIblm9T5HmYqMsQO58h8Mfx7JxU5NQVMWb5EoRgTHLNEAA5Z8g5gyltWKjGNQfvi2dbT5DN1DLAegVFIZGYQfgPQRFByUuoxTYY9yayyURAGRMYURADugBgyIA0mtDSlDY08axPoqqaYKRbhrE+F1Tm+flEkzGQMSufynVS0Sjv/AJiV3xFUhbjH1H6pXfmfxrUi8FIxMOnIfXXKu8gwb1o+dG6Edfby/T1eHhW6WB79+UofWPfWEJV8oUT+ZOuBTuz9nS4fuexQbjwfr8Pg3c9eXQcqCJPGzaTbAxV6rne3273btbptOkft4a8l5TPXtIAAAAASUVORK5CYII=',
  'silver-landing/good-time-to-invest-card-1.png' = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAABo0lEQVR4AQXBT0/TYAAH4N/WrmNua60ExrK3c6goiNCMiQdJ4IBRL5BIjBcuxBvZJ/DoSb+A38AmnmvCYR44uMREA+lmakZpHJ2Z8d8m6zrKurctzxMxGwY+fqqmDON4cypzdZvkx+VIhMJqdWojt6ssCpa6uvPMYeYX5ZR10n5x5/byy/WHywuNo298LBryc4WJmfeq+qDe6MTP/enPrGP3NldWS2WeTwvHpglCCDhQ/DJ0/Gw2hd+d03I2W9GZrSdPX3vUXdA0DblcDqIoYuT7+HPaAw1CeGeDsYMvhwk2XyByjKFYKpYQhBR2vw/qh+jaA2QkCdduzUJ5+06OjotJ5PMSslMZtKzvGPRtSIQgHvhofa3Bc8+QSKXBFIi03m5bM+IVET+aJjgEcP79xWSMIs2E2NurwLX/V1kuySsMF1nRappgdztoaIcYoyMU565jOBxiMp3orT1+pLDiZVG1+/Z8tfqhzCAUklwURl1HXT9Cgo32bt6992biRlFlNza2nP39yqsDGujmSXN7d/e5PE2y8FyvhvglZfZ+SS0trTkXFaW0clsM51MAAAAASUVORK5CYII=',
  'silver-landing/banner-silver-kilo.png' = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAABcUlEQVR4AU3BsW7aQAAG4P8O4xwIh6ihQwYadQO1G5U6kJGtU+dEkbKRIcpCGqlP0AdgREK8QBshdWkrkbkDcSY2pF6M5UDcOI7BPp/tKwNDv4/gP71eb0vK5Ium5Q6klKVCoQDXdQ87nc5Yw8Z0OkW32/3YbrfPGGM5Qgg458o0zTcAxhQbw+HQqNVqnxljOUIIhBCwbftPFIbfsUaxdntrwrKso1ar9VZKCaUUOOewbLsvROz3BwNoWBuNrivNZvMiTVOilEIYhuD8znH9+U8Q3bBnjkevf/1AFIWn9Xr9dRzHoJSCc465v/iaRnGQ5JcUKgX9Pb7ZbzTena1WK2RZhiAIYM1m82i+vNJVZalnxWW1uqeoYWx/KpfLL4UQkFJiMpngafH3W6W0fZ9tBd5usSCOj0+gGYbx3nEcEELgeR74nbUghF757N5nsf58fn6psKb5vn/04LofZJK8QhJXCZIbj6W2vso/vtgpS2z8Axzcv1QLTFgqAAAAAElFTkSuQmCC',
  'silver-landing/good-time-to-invest-card-2.png' = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAABl0lEQVR4AQXBTW/SYAAH8D88rE8LXcuYSFk7ZIkQZsJefMmygwejFw968sZH8GDiTh53M971xsWEL+Bn2GXEg1tsquNlMknUQKGvtPQNf7+Uql3ha/ecH14PXnJZvvXwweH+KonxXdUuHNftbCuVLwe7DYccHuzzc2P+7n6zftrcvdvcWM8K7BoERdqsyVvSswv1J50ZepccHR2/qldvn3oLV/QWHgxzDse24Do2SCpmFUXeU7VeP1OWii1eKIimocOybURhgFUSgTIMgiULSj1RkcstUqsqH2b6RPD9ALV6A5U7O+ByOfi+D9d1wVAOa1w+S5Yp+pahVBgOBlAvLzGdTCHmN1Bv3EN5SwZDWSx92ybp9dLTH5pW+zvREcYxAn+BP+PfqFR3UFa2Yc51jMbTs/SL5086hXzOXEVLXPWGOP+mYfRPx8ywEIUhEmTMm5txh5y8eX29MC1q2tYeZTJsHEXwowTDXyOUSiVz0O99LNzabJNP7c8Bz7NdLo1+6HtckkpniVC0RUk5EzJ4L0vF9uPjR85/HBu1GcUfJhUAAAAASUVORK5CYII=',
  'silver-landing/trusted-partners-logos.png' = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAABlElEQVR4AQ3By04TURgA4HPOnM6l43SswxRK7SCWNGKABWsIcWFi3KlRFxJdG9/BhQ/hwoQF4RWMLn0BlTRCsaTKtMC02pbWtnOf+X/7fZTM7H14z1oXI1UgYWXplvUOANKW47xFSbQt84ZbXd0E4cvnT+S02ZFThMXrevb108cPdqur63ca9eOApvgziMDLZdWENdsOTaNY1WV+W87l1xLMUABCRS2/wZGsTLxA6zgtytxoxFyFV6eIW7XDr/RvtwedcxuOat+FQRDtgB9U/vku42HImCTGeqk0Z35r2OzNk+c9IASN7fv82b2C8fGwqyEQxgWMSNDuTmqXw0GaJuQooQGZ2WGM1E+aQSErQxoj4YIogqrknJAr7bWKPLJ/lXVEJHeXi317Eh5LjP0uFAF4JqvAleSN40G/lyuXnBePHkbDoS9GPrka+9Nkfs4cS/I14OXFZRz1/WloZhqnP04MQVtQqfeHGvNa52bRqDOQXMuykJKZg4N9dnbhqLE3NcVIySMlBAVviLLeW1lacHdfvoL/IDLIw/kHIVkAAAAASUVORK5CYII=',
  'silver-landing/silver-kilo.png' = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAABDElEQVR4ARXB0XETMRSG0e+/0kqyPR6PsZOZPHjoggrghRJoLTRAJTRBHkiogCwxa3EltOeI4fHxa76/v/t2PB4/SJp67zIzXl5+fX96+vElpfxqDM/PPx8ul8vnnPNDzvm83+9P5/P5dDq9+1TMPtbfbykylFIshKCBzWaDJNwdSSHv8vtbV44M7s40TaSUWEkihIC704T0JygySMLduV6vxBhxdyThrdEasIHIYGa01iilsDIz3J0QAlKnv0FkSCmxLAsxRiRRa6W1RnNHCNsakcHMqLUyzzOr3jur1judrvbXMQZJlFLY7XYsy4Ikttst9K4BmyAymBnzPOPuHA4HVrfbjVr/0TvQ4T8g0GsummhIlQAAAABJRU5ErkJggg==',
  'homepage/investment-assistant.png' = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAABTklEQVR4AY3Bu0rDUBgA4P8k51RsbaoRK2ilom4ODl1UKDhYEAQdxE1EfQAvk0/gCwgu4uLipJMgKjjbRXQopUgvQi+hzYWmTWxzkpOYSicnvw/+C8Ef9YYMT48P66Hg0JputC4O9vZz4MPgOzw92yhL7Z3oyKB5fXP7PBUd3gxHhC3TNLMAkAMfBp+qmXOEa2/PTTD4LLr2aIjs6qpyIk6OS9DHgY8h5KmGDapuAQPEz0Yr58n54keQVY80TYMeDD7LYayqMsvM0FZyoZmaGXNipuVRqUkzoihCDwbfSiJ2uZqIXfE40JVr2dR7nhxL+sB9vUlfoI9fWl4EnuNdm9quosgYB8K1iozvjE7grVYtu9SxvUa9AVgQIhARBJ4QEvDxBGPCIQ4x5jKMCQIPfvGFfAHi03EXged4gKhhGt3Ot9FRFIWWvkpu+jUNPT+HqpWVawgVcgAAAABJRU5ErkJggg==',
  'storage-solution/secure_partners.png' = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAABmklEQVR4AS3Bz0vbUBwA8O97eWmTaFrbpCqjtpVSS3dQEeplgj8ugn/A/oAxJky8jx0nHnfaBoPi0YsHQaYXr93oRS+KdlalVmzV6tYfiUnT5L1nB/t8EPz3JbcF4DzKTseZRggFRclXUGT94e2b1/APgp5PnzegY9QlzPjq/KupjwPhkLS7/3OPgrPMBf3P2od3IOQ2f8Df2pUocDehZcfWl7LjcTUQJsOaFjst/v4FtHv9fmWZ4W6jqnaJlUE6jOoH3Gd1RbAdF6otP28CiUgim7g4u0zjtnCeCEuRl8JxM96az1c6WLWIEnJUIVdKpY8AHG+QUhYg5k2/qXt2i0ZC6vaNebeTL53IPozduFJcaCIs9jHV5Qol4Ui01m5UTJzx00ztKXYY3fUZwPlktXZpH6QqLI2LwQF/mwS0F47RqBtGwbO1qF5eFC5umeVx6WqmbSUbJuUeSQ7rHQQ9X799J/UyTzNU7xcpbwHlYI+4g35TemQEs9jQyBmBnrnZrHeoHJXuH/qwS4ECElGIe2U5KHIiSyyTSvBnhCW4EtZ9uvAAAAAASUVORK5CYII=',
  'storage-solution/gold-1kg.png' = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAABdUlEQVR4AX3BSUsCURwA8P+b5+iMS5iJWxaERQvRApYKHjr0AaLt0D0IuvUxgi4FHSKQoFOXiM5d6lKEELlQGGmrVtpUauN782ZedQg69fvBf+amRuAXgj/mJ2Mg2VuQ5HK7bLLdXSf0yvh8N+CjxE3wbWKsH7z+NuT0tQkt7cHIWNicsGulYLboX03n2DKxyG94YXoUvKF+wRnslXr6vIvjw3qiiZX9siSLrHwWq6i2JGkIl6YmfwhZvZ3u6KC4FrI9zGCVIy6IcH56ohWepb2G5rhAiIIJsBUFzHdLPvo+K1hdQBsEzpKZ+lOjY0tVezaJwygwhXAcj4cRxS7l8ToZxazqSWVuyi/G0EodBRKqRSwYpEaqlayOo0PdIMqtiiZ2Hb5UqKJZB7YIat5XwfHE6Ssp51P6+vYB4EioGQTZzpHZojCz80RjUs4QoMpqCq3k0/rGzgH8wMfpW+jz2DgHqoPONYN9arXSvV68yxiJ3SP49QWHG6ew255AsQAAAABJRU5ErkJggg==',
}
