import { EMetal } from './metal';
import { ESortQuery } from './util';

// @todo: refactor EShopQuery to extract sort keys
export enum EShopQuery {
  METAL = 'metal',
  METALS = 'metals',
  PRODUCT_TYPES = 'product_types',
  PRODUCT_PRICES = 'product_prices',
  PRODUCT_WEIGHTS = 'product_weights',
  COLLECTIONS = 'collections',
  BRANDS = 'brands',
  SELECTIONS = 'selections',
  PRICE_SORT = 'price_sort',
  ISSUE_YEAR_SORT = 'issue_year_sort',
  MONTHLY_SALE_COUNT_SORT = 'monthly_sale_count_sort',
  PREMIUM_PER_OZ_SORT = 'premium_per_oz_sort',
}

export enum EShopSortQuery {
  PRICE_SORT = 'price_sort',
  ISSUE_YEAR_SORT = 'issue_year_sort',
  MONTHLY_SALE_COUNT_SORT = 'monthly_sale_count_sort',
  PREMIUM_PER_OZ_SORT = 'premium_per_oz_sort',
}

export enum ESelectionsFilters {
  DEALS = 'deals',
  AUTO_SAVINGS = 'auto-savings',
}

export type ShopSortQuery = {
  [EShopQuery.PRICE_SORT]: ESortQuery;
  [EShopQuery.ISSUE_YEAR_SORT]: ESortQuery;
  [EShopQuery.MONTHLY_SALE_COUNT_SORT]: ESortQuery.DESC;
  [EShopQuery.PREMIUM_PER_OZ_SORT]: ESortQuery.ASC;
};

export type ShopFilterQuery = {
  [EShopQuery.METAL]: string[];
  [EShopQuery.METALS]: EMetal[];
  [EShopQuery.PRODUCT_TYPES]: string[];
  [EShopQuery.PRODUCT_PRICES]: string[];
  [EShopQuery.PRODUCT_WEIGHTS]: string[];
  [EShopQuery.COLLECTIONS]: string[];
  [EShopQuery.BRANDS]: string[];
  [EShopQuery.SELECTIONS]: string[];
};

export type ShopQuery = ShopFilterQuery & Partial<ShopSortQuery>;
