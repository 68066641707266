import setLanguage from 'next-translate/setLanguage';
import { useRouter } from 'next/router';

import type { LanguageHook, LanguageState } from '@core/type/context/language';
import type { HookStoreFn } from '@core/type/context/store';
import type { ELanguage, ELanguageTags } from '@core/type/language';
import { getLanguageFromTags, getLanguageTags } from '@core/util/language';

export const useLanguage: HookStoreFn<LanguageState, LanguageHook> = (): LanguageHook => {
  const { locale } = useRouter();

  const changeLanguage = (language: ELanguageTags) => {
    void setLanguage(getLanguageFromTags(language));
  };

  return {
    language: getLanguageTags(locale as ELanguage),
    locale: locale as ELanguage,
    changeLanguage,
  };
};
