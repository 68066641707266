import { SVG } from './SVG';
import { DynamicIconProps } from './interface-icon';

export const IconBell = ({ stroke }: DynamicIconProps) => (
  <SVG width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      stroke={stroke}
      stroke-linecap="round"
      stroke-width="2.5"
      d="m9 21 .781.52a4 4 0 0 0 4.438 0L15 21"
    />
    <path
      fill="inherit"
      stroke={stroke}
      stroke-width="2"
      d="M18 5.645C18 3.581 15.5 1 12 1S6 3.58 6 5.645c0 4.436-1.73 7.716-3.042 9.841A.984.984 0 0 0 3.79 17h16.247c.817 0 1.287-.927.82-1.598C18.74 12.358 18 10.53 18 5.645Z"
    />
  </SVG>
);
