import { ReactElement, ReactNode } from 'react';

import { EHeader } from './header';
import { ELanguageTags } from './language';
import { EFooter } from './layout';
import { DeviceSizeProps } from './media-query';

export type PathTranslationsType = {
  [key in Exclude<ELanguageTags, 'default'>]: DefaultRoute;
};

export type DefaultRoute = {
  [key in ERouting]: string;
};

export type TypeRoutes = {
  [key in ERouting]: TypeRoute;
};

export type TypeRoute = {
  accessRoute: EAccessRoute;
  sitemap: TypeSitemap;
  breadcrumb: ERouting[];
  breadcrumbWithLogin?: ERouting[];
  keyRouteDynamic?: ERoutingDynamic;
  keyRouteParent?: ERouting;
  header: {
    type: EHeader;
    hasSelectCurrency?: boolean;
    hasSelectLanguage?: boolean;
    isLight: boolean;
    shouldShowBanner?: boolean;
    shouldShowCurrencyLink?: boolean;
    shouldShowGiftPurchasingBanner?: boolean;
    hasProgressBar?: boolean;
  };
  footer: EFooter;
  noFooter: Partial<DeviceSizeProps> & { isHidden?: boolean };
  noHeader: Partial<DeviceSizeProps> & { isHidden?: boolean };
  isDarkBackground: Partial<DeviceSizeProps>;
  isFullHeightPage?: boolean;
  isFullWidthPage?: boolean;
  noPaddingMargin?: boolean;
  translation: string;
  hasCustomSitemap?: boolean;
  noIndex?: boolean;
};

export type SkeletonTypeRoutes = Partial<{
  [key in ERouting]: ReactElement;
}>;

export type TypeSitemap = {
  changefreq?: 'always' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly' | 'never';
  // number between 0.0 - 1.0
  priority?: number;
};

export enum EAccessRoute {
  // Mean if you are not connected, the route is unavailable
  CONNECTED = 'connected',
  // Mean if you are connected, the route is unavailable
  NOT_CONNECTED = 'notConnected',
  // Mean if you are connected or not connected, the route is available
  DEFAULT = 'default',
}

export enum ERouting {
  ABANDONED_CART = 'abandonedCart',
  ABOUT_US = 'aboutUs',
  ACCOUNT_TRANSACTIONS = 'accountTransactions',
  ALERT_SETTINGS = 'alertSettings',
  AUTO_SAVINGS = 'autoSavings',
  BAD_REQUEST = 'badRequest',
  BILLING_ADDRESS = 'billingAddress',
  BLOG_ARTICLE = 'blogArticle',
  BLOG_CATEGORY = 'blogCategory',
  BLOG_HOME = 'blog',
  BLOG_SEARCH = 'blogSearch',
  BLOG_TAG = 'blogTag',
  BUY = 'buy',
  CART = 'cart',
  CHANGE_PASSWORD = 'changePassword',
  CHARTS = 'charts',
  CHECKOUT_BILLING = 'checkoutBilling',
  CHECKOUT_PAYMENT = 'checkoutPayment',
  CHECKOUT_REVIEW = 'checkoutReview',
  CHECKOUT_SHIPPING = 'checkoutShipping',
  CHECKOUT_THANKS = 'checkoutThanks',
  CONTACT = 'contact',
  CONTACT_FORM = 'contactForm',
  DELIVERY_CLAIMS_BILLING_ADDRESS = 'deliveryClaimsBillingAddress',
  DELIVERY_CLAIMS_BREAKDOWN = 'deliveryClaimsBreakdown',
  DELIVERY_CLAIMS_DELIVERY = 'deliveryClaimsDelivery',
  DELIVERY_CLAIMS_ORDER_REVIEW = 'deliveryClaimsOrderReview',
  DELIVERY_CLAIMS_PAYMENT_METHOD = 'deliveryClaimsPaymentMethod',
  DELIVERY_CLAIMS_THANK_YOU = 'deliveryClaimsThankYou',
  DEPOSIT = 'deposit',
  DETAILS_GIFT = 'detailsGift',
  DONE_SIGN_UP = 'doneSignUp',
  EMAIL_UNSUBSCRIBE = 'emailUnsubscribe',
  EMERGENCY_PEOPLE = 'emergencyPeople',
  EXAMPLE = 'example',
  FAQ = 'faq',
  FAQ_SEARCH = 'faqSearch',
  FAQ_SHOW = 'faqShow',
  FORGOTTEN_PASSWORD = 'forgottenPassword',
  GIFTS = 'gifts',
  GIFT_CONFIRMATION = 'giftConfirmation',
  GIFT_PENDING = 'giftPending',
  GIFT_RECIPIENT = 'giftRecipient',
  GIFT_SUBMIT = 'giftSubmit',
  HOME = 'home',
  HOME_BUY = 'homeBuy',
  IMPRESSUM = 'impressum',
  INTERNAL_ERROR = 'internalError',
  KYC_ANNUAL_INCOME = 'kycAnnualIncome',
  KYC_BUSINESS_SECTOR = 'kycBusinessSector',
  KYC_COUNTRIES_OF_RESIDENCE = 'kycCountriesOfResidence',
  KYC_DATE_OF_BIRTH = 'kycDateOfBirth',
  KYC_DONE = 'kycDone',
  KYC_EXPOSED_PERSON = 'kycExposedPerson',
  KYC_FORECASTED_ANNUAL_INVESTMENTS = 'kycForecastedAnnualInvestments',
  KYC_LOCATION = 'kycLocation',
  KYC_NATIONALITIES = 'kycNationalities',
  KYC_OCCUPATION = 'kycOccupation',
  KYC_PHONE_NUMBER = 'kycPhoneNumber',
  KYC_PHOTO_IDENTIFICATION = 'kycPhotoIdentification',
  KYC_SOURCES_OF_FUNDS = 'kycSourcesOfFunds',
  MAINTENANCE = 'maintenance',
  MOBILE_APP_REDIRECTION = 'mobileAppRedirection',
  NOT_FOUND = 'notFound',
  PAGES = 'pages',
  PASSWORD_SETTINGS = 'passwordSettings',
  PAYMENTS = 'payments',
  PRECIOUS_METAL_GUIDE = 'preciousMetalGuide',
  PRECIOUS_METAL_GUIDE_LEVEL1 = 'preciousMetalGuideLevel1',
  PRECIOUS_METAL_GUIDE_LEVEL2 = 'preciousMetalGuideLevel2',
  PRECIOUS_METAL_GUIDE_LEVEL3 = 'preciousMetalGuideLevel3',
  PRICING = 'pricing',
  PRODUCT_SHOW = 'product',
  PROFILE = 'profile',
  PURCHASING_LIMIT = 'purchasingLimit',
  REFERRAL = 'referral',
  REFERRALS = 'referrals',
  REFERRALS_TERMS = 'referralsTerms',
  RESEND_INSTRUCTION = 'resendInstruction',
  SAFERPAY_REDIRECTION = 'saferpayRedirection',
  SAFERPAY_TRANSACTION = 'saferpayTransaction',
  SAVING_ASSISTANT = 'savingsAssistant',
  SEARCH = 'search',
  SHIPPING_ADDRESS = 'shippingAddress',
  SHOP_SUBCATEGORY = 'subcategory',
  SIGN_IN = 'signIn',
  SIGN_UP = 'signUp',
  SILVER_LANDING = 'silverLanding',
  STORAGE_FEES = 'storageFees',
  STORAGE_SOLUTION = 'storageSolution',
  TUTORIAL_VIDEO = 'tutorialVideo',
  TUTORIAL_VIDEOS = 'tutorialVideos',
  TWO_STEPS_AUTH_SMS = 'twoStepsAuthSms',
  TWO_STEPS_AUTH_TOTP = 'twoStepsAuthTotp',
  UNLOCK_ACCOUNT = 'unlockAccount',
  VERIFY_AUTH = 'verifyAuth',
  WALLET = 'wallet',
  WALLET_AUTO_SAVINGS = 'walletAutoSavings',
  WALLET_AUTO_SAVINGS_SHOW = 'walletAutoSavingsShow',
  WALLET_CLAIMS = 'walletClaims',
  WALLET_CLAIM_SHOW = 'walletClaimShow',
  WALLET_GIFTS = 'walletGifts',
  WALLET_GIFT_SHOW = 'walletGiftShow',
  WALLET_ORDERS = 'walletOrders',
  WALLET_ORDER_SHOW = 'walletOrderShow',
  WALLET_SELL = 'walletSell',
  WALLET_SELL_REVIEW = 'walletSellReview',
  WALLET_SELL_THANKS = 'walletSellThanks',
  WISHLIST = 'wishlist',
  WITHDRAWAL = 'withdrawal',
}

export enum ERoutingDynamic {
  METAL = 'metal',
  METAL_SLUG = 'metalSlug',
  METAL_CATEGORY = 'metal_category',
  PRODUCT_SHOW = 'product',
  TUTORIAL_VIDEO = 'tutorial_video',
  PRECIOUS_METAL_GUIDE = 'precious_metal_guide',
  PRECIOUS_METAL_GUIDE_LEVEL1 = 'precious_metal_guide_level_1',
  PRECIOUS_METAL_GUIDE_LEVEL2 = 'precious_metal_guide_level_2',
  PRECIOUS_METAL_GUIDE_LEVEL3 = 'precious_metal_guide_level_3',
  BLOG_CATEGORY = 'blog_category',
  BLOG_TAG = 'blog_tag',
  BLOG_ARTICLE = 'blog_article',
  CHARTS = 'charts',
  FAQ_ARTICLE = 'faq_article',
  CURRENCY = 'currency',
  SLUG = 'slug',
  PAGES = 'pages',
  SANITY = 'sanity',
}

export type LinkNext = {
  href: string | { pathname: string; query: NodeJS.Dict<string | string[]> };
  as: string | { pathname: string; query: NodeJS.Dict<string | string[]> };
};

export type LinkBaseProps = {
  keyRoute: ERouting;
  children: ReactNode;
  query?: NodeJS.Dict<string | string[]>;
};

export type LinkProps<T> = LinkBaseProps & T;
