import { AxiosInstance } from 'axios';

import { replaceAPIVersion } from './api';

export enum EConfigName {
  CONTENT_TYPE = 'Content-Type',
  LANGUAGE = 'Accept-Language',
}

export const configResponse = (
  headers: Record<string, string>,
  props: { apiVersion?: string; axiosInstance?: AxiosInstance } = {},
) => {
  const extra = {};
  if (props.apiVersion && props.axiosInstance) {
    Object.assign(extra, { baseURL: replaceAPIVersion(props as Required<typeof props>) });
  }
  return {
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    ...extra,
  };
};
