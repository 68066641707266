import { metalIsoToFullMetalName, productTypeToFullProductType } from '@core/constant';
import { useContextCurrency, useContextProfile, useContextUser } from '@core/context';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useLanguage } from '@core/hook/use-language';
import {
  EContactMethod,
  EGTMLoginStatus,
  ELanguage,
  EMetal,
  EMixpanelEventName,
  EMixpanelInteractionType,
  EMixpanelProductAvailability,
  EShopQuery,
  EUserType,
  MixpanelEventProps,
  MixpanelHeaderEventProps,
  Price,
} from '@core/type';
import {
  CartProductEntity,
  CollectionsBannersImageEntity,
  InvestmentChartsResponse,
  OrderResponse,
  ProductBlockEntity,
  ProductEntity,
} from '@core/type/api';

import { useContextMixpanel } from '../context-mixpanel';
import { trackEvent } from '../helpers';
import { MixpanelProductEntity } from '../interface-mixpanel';

export const useEventMixpanel = () => {
  const { locale } = useLanguage();
  const { currency } = useContextCurrency();
  const { enable, environment, isReady } = useContextMixpanel();
  const { isLogged } = useContextUser();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { userId, userGaPayUsed, userType: profileUserType } = useContextProfile();

  const userType = profileUserType ?? EUserType.USER;
  const loginStatus = isLogged ? EGTMLoginStatus.LOGGED_IN : EGTMLoginStatus.NOT_LOGGED_IN;

  const baseProps: Pick<MixpanelEventProps, 'language' | 'currency' | 'environment'> = {
    language: locale === ELanguage.DEFAULT ? ELanguage.EN : locale,
    currency,
    environment,
  };

  const getProductProps = (
    props: MixpanelProductEntity,
  ): Omit<MixpanelEventProps, 'language' | 'currency' | 'environment'> => ({
    productId: props.id.toString(),
    productCategory: metalIsoToFullMetalName[props.metalIso],
    productName: props.nameEn,
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    productPrice: props.price || Math.round((props.totalPrice / props.quantity) * 100) / 100,
    productType: productTypeToFullProductType[props.productType],
    productWeight: props.weight.toString(),
    productQuantity: props.quantity,
  });

  const trackProductAddedToCartEvent = (props: MixpanelProductEntity) => {
    if (enable && props?.nameEn) {
      trackEvent(EMixpanelEventName.PRODUCT_ADDED_TO_CART, {
        ...baseProps,
        ...getProductProps(props),
        loginStatus,
        packageSelected: props.packageSelected || null,
      });
    }
  };

  const trackProductRemovedFromCartEvent = (props: MixpanelProductEntity) => {
    if (enable && props?.nameEn) {
      trackEvent(EMixpanelEventName.PRODUCT_REMOVED_FROM_CART, {
        ...baseProps,
        ...getProductProps(props),
        loginStatus,
      });
    }
  };

  const trackPageViewedEvent = () => {
    if (enable) {
      trackEvent(EMixpanelEventName.PAGE_VIEWED, { ...baseProps });
    }
  };

  const trackCartViewedEvent = (cart: OrderResponse) => {
    if (enable) {
      trackEvent(EMixpanelEventName.CART_VIEWED, {
        ...baseProps,
        cartSize: cart?.products?.length,
        cartValue: cart?.totalPrice,
        productsCategories: cart?.products?.map(
          ({ metalIso }) => metalIsoToFullMetalName[metalIso],
        ),
        productsIds: cart?.products?.map(({ id }) => id),
        productsNames: cart?.products?.map(({ nameEn }) => nameEn),
        productsPrices: cart?.products?.map(({ totalPrice }) => totalPrice),
      });
    }
  };

  const trackCarouselProductsEvent = ({ id, nameEn }: ProductEntity, productGroup: string) => {
    if (enable) {
      trackEvent(EMixpanelEventName.CAROUSEL_PRODUCTS, {
        ...baseProps,
        loginStatus,
        productId: id.toString(),
        productName: nameEn,
        productGroup,
        userGaPayUsed,
      });
    }
  };

  const trackRecentlyViewedProductEvent = (product: ProductEntity) => {
    if (enable) {
      trackEvent(EMixpanelEventName.CAROUSEL_RECENTLY_VIEWED_PRODUCTS, {
        ...baseProps,
        productId: product.id.toString(),
        productName: product.nameEn,
        pageUrl: window.location.pathname,
        userId: `${userId ?? ''}`,
        loginStatus,
        userType,
      });
    }
  };

  const trackRecentlyViewedProductAddedToCartEvent = (product: CartProductEntity) => {
    if (enable) {
      trackEvent(EMixpanelEventName.CAROUSEL_RECENTLY_VIEWED_PRODUCTS_ADDTOCART, {
        ...baseProps,
        productId: product.id.toString(),
        productName: product.nameEn,
        productCategory: metalIsoToFullMetalName[product.metalIso],
        productPrice: product.totalPrice,
        productType: productTypeToFullProductType[product.productType],
        productWeight: product.weight.toString(),
        productQuantity: product.quantity,
        pageUrl: window.location.pathname,
        userId: `${userId ?? ''}`,
        loginStatus,
        userType,
      });
    }
  };

  const trackCarouselBannersEvent = ({ id }: Partial<CollectionsBannersImageEntity>) => {
    if (enable) {
      trackEvent(EMixpanelEventName.CAROUSEL_BANNER, {
        ...baseProps,
        loginStatus,
        imageId: id,
        userGaPayUsed,
      });
    }
  };

  const trackAccountCreationDonePageEvent = (
    name: MixpanelEventProps['accountCreationDoneCtasName'],
  ) => {
    if (enable) {
      trackEvent(EMixpanelEventName.ACCOUNT_CREATION_DONE_PAGE, {
        ...baseProps,
        loginStatus,
        accountCreationDoneCtasName: name,
      });
    }
  };

  const trackAbTestEvent = (abTestKey: string, isVariant: boolean) => {
    if (enable && isReady) {
      trackEvent(EMixpanelEventName.AB_TEST, {
        ...baseProps,
        loginStatus,
        abTestKey,
        isVariant,
      });
    }
  };

  const trackInvestmentChartsEvent = ({
    eventName,
    metalIso,
    initialInvestment,
    monthlyInvestment,
  }: { eventName: EMixpanelEventName } & Partial<InvestmentChartsResponse>) => {
    if (enable) {
      trackEvent(eventName, {
        ...baseProps,
        loginStatus,
        metal: metalIsoToFullMetalName[metalIso],
        initialInvestment,
        monthlyInvestment,
      });
    }
  };

  const trackSavingAssistantEvent = ({ metalIso, amount }: { metalIso: EMetal; amount: Price }) => {
    if (enable) {
      trackEvent(EMixpanelEventName.SAVINGS_ASSISTANT_USED, {
        ...baseProps,
        loginStatus,
        assistantMetal: metalIsoToFullMetalName[metalIso],
        assistantBudget: amount,
        userGaPayUsed,
      });
    }
  };

  const trackHeaderEvents = ({ sectionType, sectionName, urlPath }: MixpanelHeaderEventProps) => {
    if (enable) {
      trackEvent(EMixpanelEventName.HEADER_INTERACTION, {
        ...baseProps,
        loginStatus,
        sectionType,
        sectionName,
        userType,
        urlPath,
      });
    }
  };

  const trackProductFilterEvent = (filterType: EShopQuery, filterName: string) => {
    if (enable) {
      trackEvent(EMixpanelEventName.FILTERS_INTERACTION, {
        ...baseProps,
        loginStatus,
        userType,
        filterType,
        filterName,
      });
    }
  };

  const trackBudgetCalculatorEvent = (
    product: ProductBlockEntity,
    interactionType: EMixpanelInteractionType,
    budgetValue: Price,
    quantityValue: number,
    pageUrl: string,
    productPrice: Price,
    productQuantity: number,
  ) => {
    if (enable) {
      trackEvent(EMixpanelEventName.BUDGET_CALCULATOR, {
        ...baseProps,
        loginStatus,
        userType,
        interactionType,
        budgetValue,
        quantityValue,
        pageUrl,
        productId: `${product.id}`,
        productName: product.name,
        productPrice,
        productAvailability: product.preorder?.isPreorder
          ? EMixpanelProductAvailability.PREORDER
          : EMixpanelProductAvailability.IN_STOCK,
        productQuantity,
        userId: `${userId ?? ''}`,
      });
    }
  };

  const trackAlertEvent = (
    name: EMixpanelEventName,
    action: 'update' | 'create' | 'delete',
    pageUrl: string,
    notificationType: EContactMethod,
    data:
      | Pick<MixpanelEventProps, 'alertValue' | 'condition' | 'metal'>
      | Pick<MixpanelEventProps, 'alertValue'>
      | Pick<MixpanelEventProps, 'quantityValue'>,
    product?: ProductEntity,
  ) => {
    if (enable) {
      const productData = product && {
        productId: product.id.toString(),
        productCategory: metalIsoToFullMetalName[product.metalIso],
        productName: product.nameEn,
      };

      trackEvent(name, {
        ...baseProps,
        ...data,
        ...productData,
        loginStatus,
        userType,
        userId: userId?.toString() ?? '',
        action,
        pageUrl,
        notificationType,
      });
    }
  };

  return {
    trackAlertEvent,
    trackProductAddedToCartEvent,
    trackProductRemovedFromCartEvent,
    trackPageViewedEvent,
    trackCartViewedEvent,
    trackCarouselProductsEvent,
    trackCarouselBannersEvent,
    trackAccountCreationDonePageEvent,
    trackAbTestEvent,
    trackInvestmentChartsEvent,
    trackSavingAssistantEvent,
    trackHeaderEvents,
    trackProductFilterEvent,
    trackBudgetCalculatorEvent,
    trackRecentlyViewedProductEvent,
    trackRecentlyViewedProductAddedToCartEvent,
  };
};
