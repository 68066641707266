export enum EColor {
  // semantic types
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ACCENT = 'accent',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  DESTRUCTIVE = 'destructive',
  INFO = 'info',
  NEUTRAL = 'neutral',

  // common types
  BLACK = 'black',
  BLUE = 'blue',
  GRAY = 'gray',
  GREEN = 'green',
  RED = 'red',
  WHITE = 'white',
  YELLOW = 'yellow',
  PURPLE = 'purple',

  // variant types
  MAIN = 'main',
  LIGHT = 'light',
  DARK = 'dark',

  // design element types
  TEXT = 'text',
  BACKGROUND = 'background',
  BOX_SHADOW = 'boxShadow',

  // intensity types. R - Regular, A - Accent.
  R50 = 'r50',
  R100 = 'r100',
  R200 = 'r200',
  R300 = 'r300',
  R400 = 'r400',
  R500 = 'r500',
  R600 = 'r600',
  R700 = 'r700',
  R800 = 'r800',
  R900 = 'r900',
  A50 = 'a50',
  A100 = 'a100',
  A200 = 'a200',
  A300 = 'a300',
  A400 = 'a400',
  A500 = 'a500',
  A600 = 'a600',
  A700 = 'a700',
  A800 = 'a800',
  A900 = 'a900',

  // box shadow elevation types
  ELEVATION_1 = 'elevation1',
  ELEVATION_2 = 'elevation2',
  ELEVATION_3 = 'elevation3',
  ELEVATION_4 = 'elevation4',
  ELEVATION_5 = 'elevation5',
  ELEVATION_6 = 'elevation6',

  // other types
  TRANSPARENT = 'transparent',
}

/* For adding opacity to the hex color, need to put value of opacity after hex color.
 Each % of opacity has specific value.
 For example, for #000000 (black color) with opacity 90% it will be: #000000E6.
 All hex value from 100% to 0% alpha:
 100% — FF
 99% — FC
 98% — FA
 97% — F7
 96% — F5
 95% — F2
 94% — F0
 93% — ED
 92% — EB
 91% — E8
 90% — E6
 89% — E3
 88% — E0
 87% — DE
 86% — DB
 85% — D9
 84% — D6
 83% — D4
 82% — D1
 81% — CF
 80% — CC
 79% — C9
 78% — C7
 77% — C4
 76% — C2
 75% — BF
 74% — BD
 73% — BA
 72% — B8
 71% — B5
 70% — B3
 69% — B0
 68% — AD
 67% — AB
 66% — A8
 65% — A6
 64% — A3
 63% — A1
 62% — 9E
 61% — 9C
 60% — 99
 59% — 96
 58% — 94
 57% — 91
 56% — 8F
 55% — 8C
 54% — 8A
 53% — 87
 52% — 85
 51% — 82
 50% — 80
 49% — 7D
 48% — 7A
 47% — 78
 46% — 75
 45% — 73
 44% — 70
 43% — 6E
 42% — 6B
 41% — 69
 40% — 66
 39% — 63
 38% — 61
 37% — 5E
 36% — 5C
 35% — 59
 34% — 57
 33% — 54
 32% — 52
 31% — 4F
 30% — 4D
 29% — 4A
 28% — 47
 27% — 45
 26% — 42
 25% — 40
 24% — 3D
 23% — 3B
 22% — 38
 21% — 36
 20% — 33
 19% — 30
 18% — 2E
 17% — 2B
 16% — 29
 15% — 26
 14% — 24
 13% — 21
 12% — 1F
 11% — 1C
 10% — 1A
 9% — 17
 8% — 14
 7% — 12
 6% — 0F
 5% — 0D
 4% — 0A
 3% — 08
 2% — 05
 1% — 03
 0% — 00
 * */

export enum EColorName {
  // Blues
  // Regular shades
  OXFORD_BLUE = '#00264D',
  OXFORD_BLUE_20 = '#00264D33',
  OXFORD_BLUE_30 = '#00264D4D',
  // Accent shades
  ALICE_BLUE = '#F0F9FF',
  LIGHT_BLUE = '#E3F4FF',
  COLUMBIA_BLUE = '#D4EEFF',
  JORDY_BLUE = '#80BAF4',
  EPIC_BLUE = '#0074E8',
  ELECTRIC_BLUE = '#0059E8',
  MAASTRICHT_BLUE = '#001B37',
  PERSIAN_BLUE = '#1840c0',
  MOODY_BLUE = '#6d7dd7',

  // Grays
  // Regulars shades
  PAYNE_GRAY = '#4C6782',
  FRENCH_GRAY = '#CCD4DB',
  PLATINUM = '#E8E8E8',
  TIMBERWOLF = '#D1D1D1',
  DAVY_GRAY = '#494949',
  RIVER_BED_12 = '#404b571F',
  RIVER_BED_15 = '#404b5726',
  RIVER_BED_20 = '#404b5733',
  RIVER_BED_26 = '#404b5742',
  CADET_GRAY = '#8093A6',
  LOTION = '#FAFAFA',
  // Accent shades
  SEASALT = '#FAFBFB',
  SOLITUDE = '#E5E9ED',
  BATTLESHIP_GRAY = '#8D8D8D',

  // Blacks
  // Regular shades
  EERIE_BLACK = '#1B1B1B',
  BLACK_12 = '#0000001F',
  BLACK_14 = '#00000024',
  BLACK_20 = '#00000033',
  BLACK = '#000000',

  // Reds
  // Regular shades
  TEA_ROSE = '#FDD4D4',
  LIGHT_RED = '#FB9494',
  BRIGHT_RED = '#F62929',
  // Accent shades
  SNOW = '#FFF4F4',
  MISTY_ROSE = '#FEEAEA',

  // Purples
  // Regular shades
  LAVENDER = '#c86dd7',
  COMET = '#565378',
  ROYAL_BLUE = '#5854ED',
  PURPLE_HEART = '#4d32b6',
  SCARLET_GUM = '#502c61',

  // Yellows
  // Regular shades
  OLD_LACE = '#F7EDDE',
  PEARL_LUSTA = '#EBD2AD',
  BABY_POWDER = '#FDFBF7',
  CAPE_HONEY = '#feecb3',
  WHEAT = '#fce38a',
  ECRU = '#DEB77B',
  SWEET_HONEY = '#D6A55A',

  // Greens
  // Regular shades
  HONEYDEW = '#E7FAF2',
  MINT_GREEN = '#CFF5E5',
  EMERALD_GREEN = '#0ECC7C',
  // Accent shades
  MINT_CREAM = '#F3FDF9',
  CHARLOTTE = '#a2f3f7',
  BRIGHT_TURQUOISE = '#20e9c7',
  BLUE_STONE = '#106657',

  // Whites
  // Regular shades
  FLORAL_WHITE = '#FBF6EE',
  ANTI_FLASH_WHITE = '#F2F4F6',
  WHITE_SMOKE = '#F4F4F4',
  WHITE_10 = '#ffffff1A',
  WHITE_40 = '#ffffff66',
  WHITE_70 = '#ffffffB3',
  WHITE_80 = '#ffffffCC',
  WHITE = '#ffffff',

  // Other
  TRANSPARENT = 'transparent',
}

export type ColorType<Color extends Partial<EColor>, P = EColorName> = {
  [key in Color]: P;
};

export type SemanticColorType = keyof ColorType<
  | EColor.PRIMARY
  | EColor.SECONDARY
  | EColor.SUCCESS
  | EColor.WARNING
  | EColor.DANGER
  | EColor.INFO
  | EColor.NEUTRAL
>;

export type CommonColorType = keyof ColorType<
  | EColor.BLACK
  | EColor.BLUE
  | EColor.GRAY
  | EColor.GREEN
  | EColor.PURPLE
  | EColor.RED
  | EColor.WHITE
  | EColor.YELLOW
>;

export type VariantColorType = keyof ColorType<EColor.MAIN | EColor.LIGHT | EColor.DARK>;

export type IntensityColorType = keyof ColorType<
  | EColor.A50
  | EColor.A100
  | EColor.A200
  | EColor.A300
  | EColor.A400
  | EColor.A500
  | EColor.A600
  | EColor.A700
  | EColor.A800
  | EColor.A900
  | EColor.R50
  | EColor.R100
  | EColor.R200
  | EColor.R300
  | EColor.R400
  | EColor.R500
  | EColor.R600
  | EColor.R700
  | EColor.R800
  | EColor.R900
>;

export type TextColorType = keyof ColorType<
  EColor.SECONDARY | EColor.ACCENT | EColor.DESTRUCTIVE | EColor.DANGER
>;

export type BackgroundColorType = keyof ColorType<
  EColor.SECONDARY | EColor.ACCENT | EColor.WARNING | EColor.INFO | EColor.NEUTRAL
>;

export type BoxShadowElevationType = keyof ColorType<
  | EColor.ELEVATION_1
  | EColor.ELEVATION_2
  | EColor.ELEVATION_3
  | EColor.ELEVATION_4
  | EColor.ELEVATION_5
  | EColor.ELEVATION_6
>;

export type DesignElementType = keyof ColorType<EColor.TEXT | EColor.BACKGROUND>;

export type SemanticColorProps<T extends SemanticColorType | TextColorType | BackgroundColorType> =
  {
    semanticType: SemanticColorType | T;
    variant?: VariantColorType;
  };

export type CommonColorProps = {
  commonType: CommonColorType;
  intensity: IntensityColorType;
};

export type ColorProps<T extends TextColorType | BackgroundColorType = null> =
  | SemanticColorType
  | T
  | EColor.TRANSPARENT
  | SemanticColorProps<T>
  | CommonColorProps;
