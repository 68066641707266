import { ELanguageTags } from '@core/type/language';

const i18nSupportedLanguage: Exclude<ELanguageTags, ELanguageTags.DEFAULT>[] = [
  ELanguageTags.EN_US,
  ELanguageTags.FR_FR,
  ELanguageTags.IT_IT,
  ELanguageTags.DE_DE,
];

export class Language {
  public static readonly i18nSupportedLanguage = i18nSupportedLanguage;
  public static readonly I18N_DEFAULT_LANGUAGE = ELanguageTags.EN_US;
}
