import { ELanguageTags } from '@core/type/language';
import { ERouting } from '@core/type/routing';

import { EOgImage, OgImageRouterType, OgImages } from '../interfaces';

const defaultOgImages: OgImageRouterType = {
  [ERouting.HOME]: EOgImage.DEFAULT_IMAGE,
  [ERouting.TUTORIAL_VIDEOS]: EOgImage.TUTORIAL_VIDEOS,
  [ERouting.SILVER_LANDING]: EOgImage.SILVER_LANDING_PAGE_EN,
  [ERouting.AUTO_SAVINGS]: EOgImage.AUTO_SAVINGS,
};

export const ogImages: OgImages = {
  [ELanguageTags.IT_IT]: {
    ...defaultOgImages,
    [ERouting.SILVER_LANDING]: EOgImage.SILVER_LANDING_PAGE_IT,
  },
  [ELanguageTags.FR_FR]: {
    ...defaultOgImages,
    [ERouting.SILVER_LANDING]: EOgImage.SILVER_LANDING_PAGE_FR,
  },
  [ELanguageTags.DE_DE]: {
    ...defaultOgImages,
    [ERouting.SILVER_LANDING]: EOgImage.SILVER_LANDING_PAGE_DE,
  },
  [ELanguageTags.EN_US]: {
    ...defaultOgImages,
  },
  [ELanguageTags.DEFAULT]: {
    ...defaultOgImages,
  },
};
