import { EPosition, IconPosition } from '@core/type';

export const getTranslationWithOrientation = (
  orientation: IconPosition,
  yTranslation = '0',
): string => {
  switch (orientation) {
    case EPosition.TOP:
      return `${getRotationWithOrientation(orientation)} translate(0,-${yTranslation})`;
    case EPosition.RIGHT:
      return `${getRotationWithOrientation(orientation)} translate(-${yTranslation},0)`;
    case EPosition.LEFT:
      return `${getRotationWithOrientation(orientation)} translate(${yTranslation},0)`;
    case EPosition.BOTTOM:
      return `${getRotationWithOrientation(orientation)} translate(-${yTranslation},0)`;
    default:
      return null;
  }
};

const getRotationWithOrientation = (orientation: IconPosition): string => {
  switch (orientation) {
    case EPosition.TOP:
      return `rotate(180deg)`;
    case EPosition.RIGHT:
      return `rotate(270deg)`;
    case EPosition.LEFT:
      return `rotate(90deg)`;
    case EPosition.BOTTOM:
      return null;
    default:
      return null;
  }
};
