import { useEffect } from 'react';

import { useContextRouting } from '@core/context';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useLanguage } from '@core/hook/use-language';
import { getFullUrl } from '@core/util';

import { useContextMixpanel } from '../context-mixpanel';
import { useEventMixpanel } from './use-event-mixpanel';

export const useEventMixpanelPageViewed = () => {
  const { enable, isReady, host } = useContextMixpanel();
  const { keyRoute, query, pathTranslations } = useContextRouting();
  const { trackPageViewedEvent } = useEventMixpanel();
  const { language } = useLanguage();

  const fullUrl = getFullUrl(host, language, keyRoute, pathTranslations, query);

  useEffect(() => {
    if (enable && isReady) {
      trackPageViewedEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullUrl, isReady]);
};
