// This file is created cause next.config.js work only with JS file
// It is the same as { ERouting } of @core/type
module.exports = Object.freeze({
  ABANDONED_CART: 'abandonedCart',
  ABOUT_US: 'aboutUs',
  ACCOUNT_TRANSACTIONS: 'accountTransactions',
  ALERT_SETTINGS: 'alertSettings',
  AUTO_SAVINGS: 'autoSavings',
  BAD_REQUEST: 'badRequest',
  BILLING_ADDRESS: 'billingAddress',
  BLOG_ARTICLE: 'blogArticle',
  BLOG_CATEGORY: 'blogCategory',
  BLOG_HOME: 'blog',
  BLOG_SEARCH: 'blogSearch',
  BLOG_TAG: 'blogTag',
  BUY: 'buy',
  CART: 'cart',
  CHANGE_PASSWORD: 'changePassword',
  CHARTS: 'charts',
  CHECKOUT_BILLING: 'checkoutBilling',
  CHECKOUT_PAYMENT: 'checkoutPayment',
  CHECKOUT_REVIEW: 'checkoutReview',
  CHECKOUT_SHIPPING: 'checkoutShipping',
  CHECKOUT_THANKS: 'checkoutThanks',
  CONTACT: 'contact',
  CONTACT_FORM: 'contactForm',
  DELIVERY_CLAIMS_BILLING_ADDRESS: 'deliveryClaimsBillingAddress',
  DELIVERY_CLAIMS_BREAKDOWN: 'deliveryClaimsBreakdown',
  DELIVERY_CLAIMS_DELIVERY: 'deliveryClaimsDelivery',
  DELIVERY_CLAIMS_ORDER_REVIEW: 'deliveryClaimsOrderReview',
  DELIVERY_CLAIMS_PAYMENT_METHOD: 'deliveryClaimsPaymentMethod',
  DELIVERY_CLAIMS_THANK_YOU: 'deliveryClaimsThankYou',
  DEPOSIT: 'deposit',
  DETAILS_GIFT: 'detailsGift',
  DONE_SIGN_UP: 'doneSignUp',
  EMAIL_UNSUBSCRIBE: 'emailUnsubscribe',
  EMERGENCY_PEOPLE: 'emergencyPeople',
  EXAMPLE: 'example',
  FAQ: 'faq',
  FAQ_SEARCH: 'faqSearch',
  FAQ_SHOW: 'faqShow',
  FORGOTTEN_PASSWORD: 'forgottenPassword',
  GIFTS: 'gifts',
  GIFT_CONFIRMATION: 'giftConfirmation',
  GIFT_PENDING: 'giftPending',
  GIFT_RECIPIENT: 'giftRecipient',
  GIFT_SUBMIT: 'giftSubmit',
  HOME: 'home',
  HOME_BUY: 'homeBuy',
  IMPRESSUM: 'impressum',
  INTERNAL_ERROR: 'internalError',
  KYC_ANNUAL_INCOME: 'kycAnnualIncome',
  KYC_BUSINESS_SECTOR: 'kycBusinessSector',
  KYC_COUNTRIES_OF_RESIDENCE: 'kycCountriesOfResidence',
  KYC_DATE_OF_BIRTH: 'kycDateOfBirth',
  KYC_DONE: 'kycDone',
  KYC_EXPOSED_PERSON: 'kycExposedPerson',
  KYC_FORECASTED_ANNUAL_INVESTMENTS: 'kycForecastedAnnualInvestments',
  KYC_LOCATION: 'kycLocation',
  KYC_NATIONALITIES: 'kycNationalities',
  KYC_OCCUPATION: 'kycOccupation',
  KYC_PHONE_NUMBER: 'kycPhoneNumber',
  KYC_PHOTO_IDENTIFICATION: 'kycPhotoIdentification',
  KYC_SOURCES_OF_FUNDS: 'kycSourcesOfFunds',
  MAINTENANCE: 'maintenance',
  MOBILE_APP_REDIRECTION: 'mobileAppRedirection',
  NOT_FOUND: 'notFound',
  PAGES: 'pages',
  PASSWORD_SETTINGS: 'passwordSettings',
  PAYMENTS: 'payments',
  PRECIOUS_METAL_GUIDE: 'preciousMetalGuide',
  PRECIOUS_METAL_GUIDE_LEVEL1: 'preciousMetalGuideLevel1',
  PRECIOUS_METAL_GUIDE_LEVEL2: 'preciousMetalGuideLevel2',
  PRECIOUS_METAL_GUIDE_LEVEL3: 'preciousMetalGuideLevel3',
  PRICING: 'pricing',
  PRODUCT_SHOW: 'product',
  PROFILE: 'profile',
  PURCHASING_LIMIT: 'purchasingLimit',
  REFERRAL: 'referral',
  REFERRALS: 'referrals',
  REFERRALS_TERMS: 'referralsTerms',
  SAFERPAY_REDIRECTION: 'saferpayRedirection',
  SAFERPAY_TRANSACTION: 'saferpayTransaction',
  SAVING_ASSISTANT: 'savingsAssistant',
  SEARCH: 'search',
  SHIPPING_ADDRESS: 'shippingAddress',
  SHOP_SUBCATEGORY: 'subcategory',
  SIGN_IN: 'signIn',
  SIGN_UP: 'signUp',
  SILVER_LANDING: 'silverLanding',
  STORAGE_FEES: 'storageFees',
  STORAGE_SOLUTION: 'storageSolution',
  TUTORIAL_VIDEO: 'tutorialVideo',
  TUTORIAL_VIDEOS: 'tutorialVideos',
  TWO_STEPS_AUTH_SMS: 'twoStepsAuthSms',
  TWO_STEPS_AUTH_TOTP: 'twoStepsAuthTotp',
  UNLOCK_ACCOUNT: 'unlockAccount',
  VERIFY_AUTH: 'verifyAuth',
  WALLET: 'wallet',
  WALLET_AUTO_SAVINGS: 'walletAutoSavings',
  WALLET_AUTO_SAVINGS_SHOW: 'walletAutoSavingsShow',
  WALLET_CLAIMS: 'walletClaims',
  WALLET_CLAIM_SHOW: 'walletClaimShow',
  WALLET_GIFTS: 'walletGifts',
  WALLET_GIFT_SHOW: 'walletGiftShow',
  WALLET_ORDERS: 'walletOrders',
  WALLET_ORDER_SHOW: 'walletOrderShow',
  WALLET_SELL: 'walletSell',
  WALLET_SELL_REVIEW: 'walletSellReview',
  WALLET_SELL_THANKS: 'walletSellThanks',
  WISHLIST: 'wishlist',
  WITHDRAWAL: 'withdrawal',
});
